export interface ITokenUserDto {
  id?: string
  email: string
  tradeShowId?: string
  exhibitorId?: string
  isAdmin: boolean
}

export class UserToken implements ITokenUserDto {
  id?: string
  email: string
  tradeShowId?: string
  exhibitorId?: string
  isAdmin: boolean

  constructor(userToken: ITokenUserDto) {
    this.id = userToken.id
    this.email = userToken.email
    this.isAdmin = userToken.isAdmin
    this.tradeShowId = userToken.tradeShowId
    this.exhibitorId = userToken.exhibitorId
  }
}
