/* eslint-disable func-names */
import { resetRequestInterceptor, setRequestInterceptor } from 'config/axios'
import { HubCapsule } from '@aws-amplify/core'

export const hubCallback = (completeSignInWithGoogle: any, logoutCallback: any) => {
  return function ({ payload: { event } }: HubCapsule) {
    switch (event) {
      case 'signOut': {
        // reset so we can stop any request
        resetRequestInterceptor()

        // we need it back so we can make request with a new bearer
        setRequestInterceptor(logoutCallback)
        break
      }
      case 'cognitoHostedUI_failure': {
        break
      }
      case 'cognitoHostedUI': {
        completeSignInWithGoogle()
        break
      }
      default: {
        break
      }
    }
  }
}
