import { Button, Group, Modal, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Tradeshow } from 'api/dto/tradeshow'
import { useSendEmailsVisitor } from 'api/query/visitor'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface SendEmailsVisitorProps {
  opened: boolean
  onClose: () => void
}

export function SendEmailsVisitor({ opened, onClose }: SendEmailsVisitorProps) {
  const { t } = useTranslation()
  const { tradeshowId } = useParams()
  const form = useForm<Tradeshow>({})
  const { mutateAsync: sendEmails } = useSendEmailsVisitor(tradeshowId!)
  const [submitting, setSubmitting] = useState(false)

  async function onSubmit() {
    setSubmitting(true)
    await sendEmails()
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title={t('visitor.send-email')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text size="15px">{t('visitor.email-are-you-sure')}</Text>

        <Group mt={30} position="right">
          <Button disabled={submitting} variant="outline" size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>

          <Button loading={submitting} disabled={submitting} size="xs" variant="outline" color="green" type="submit">
            {t('common.send')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
