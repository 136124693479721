/* eslint-disable @typescript-eslint/naming-convention */
import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core'
import { IconUsersGroup, IconUserStar, IconScanEye } from '@tabler/icons-react'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

interface MainLinkProps {
  icon: React.ReactNode
  color: string
  label: string

  realPath: string
  href: string
}

function AppLink({ icon, color, label, href, realPath }: MainLinkProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const splitted = pathname.split('/')
  const isActive = splitted[splitted.length - 1] === realPath

  return (
    <UnstyledButton
      mb={10}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,

        '&:hover': !isActive
          ? {
              color: theme.black,
              backgroundColor: theme.colors.gray[3],
            }
          : {},

        backgroundColor: isActive ? theme.colors.gray[6] : theme.white,
        color: isActive ? theme.white : theme.black,
      })}
      onClick={() => navigate(href)}
    >
      <Group>
        <ThemeIcon color={isActive ? 'white.0' : color} variant="outline">
          {icon}
        </ThemeIcon>

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  )
}

const links = (t: (key: string) => string, isAdmin?: boolean): MainLinkProps[] => {
  const links: MainLinkProps[] = [
    {
      icon: <IconUsersGroup size="1rem" />,
      color: 'custom-primary-color.0',
      label: t('sidebar.tradeshows'),
      href: '/',
      realPath: 'tradeshows',
    },
  ]

  if (isAdmin) {
    links.push({
      icon: <IconUserStar size="1rem" />,
      color: 'custom-primary-color.0',
      label: t('sidebar.kiosks'),
      href: 'kiosks',
      realPath: 'kiosks',
    })
  }

  return links
}

export function AppLinks() {
  const { t } = useTranslation()
  const { user } = useAuth()
  return (
    <>
      {links(t, user?.isAdmin).map((link) => (
        <AppLink {...link} key={link.label} />
      ))}
    </>
  )
}
