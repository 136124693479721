import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Exhibitor, ExhibitorDto } from 'api/dto/exhibitor'
import { Visitor, VisitorDto } from 'api/dto/visitor'
import { AxiosResponse } from 'axios'
import axios from 'config/axios'
import { environment } from 'config/environment'
import { useCallback } from 'react'

export function useExhibitors(tradeshowId?: string) {
  return useQuery(
    [`exhibitors-${tradeshowId}`],
    async () => {
      const { data: exhibitors } = await axios.get<ExhibitorDto[]>(
        `${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors`,
      )

      return exhibitors.map((exhibitor) => new Exhibitor(exhibitor))
    },
    {
      enabled: tradeshowId != null,
    },
  )
}

export function useGetExhibitor(tradeshowId?: string, exhibitorId?: string) {
  return useQuery(
    [`exhibitor-${exhibitorId}`],
    async () => {
      const { data: exhibitor } = await axios.get<ExhibitorDto>(
        `${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/${exhibitorId}`,
      )

      return exhibitor
    },
    {
      enabled: tradeshowId != null && exhibitorId != null,
      select: useCallback((data: ExhibitorDto) => new Exhibitor(data), []),
    },
  )
}

export function useAddExhibitor(tradeshowId: string) {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<ExhibitorDto>, any, any, any>({
    mutationFn: async (exhibitor) => {
      const { data: exhibitors } = await axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors`, exhibitor)

      return exhibitors
    },
    onMutate: async (newExhibitor) => {
      await queryClient.cancelQueries([`exhibitors-${tradeshowId}`])

      const previousExhibitors: Exhibitor[] = queryClient.getQueryData([`exhibitors-${tradeshowId}`]) || []

      const addedExhibitors = [...previousExhibitors, new Exhibitor(newExhibitor)]

      queryClient.setQueryData([`exhibitors-${tradeshowId}`], addedExhibitors)

      return { newExhibitor }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [`exhibitors-${tradeshowId}`] }),
  })
}

export function useListExhibitorRelations(exhibitorId?: string, tradeshowId?: string) {
  return useQuery(
    [`my-relations-${tradeshowId}`],
    async () => {
      const { data: users } = await axios.get<VisitorDto[]>(
        `${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/${exhibitorId}/relations`,
      )

      return users.map((user) => new Visitor(user))
    },
    { enabled: exhibitorId != null && tradeshowId != null },
  )
}

export function useAddExhibitorRelation(exhibitorId?: string, tradeshowId?: string) {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<Visitor>, any, any, any>({
    mutationFn: async ({ id, note }: { id: string; note?: string }) =>
      axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/${exhibitorId}/relations`, {
        userId: id,
        note,
      }),
    onSuccess: () => queryClient.invalidateQueries([`my-relations-${tradeshowId}`]),
    onError: () => queryClient.invalidateQueries([`my-relations-${tradeshowId}`]),
  })
}

export function useDeleteExhibitorRelation(exhibitorId?: string, tradeshowId?: string) {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<void>, any, any, any>({
    mutationFn: async (userId) =>
      axios.delete(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/${exhibitorId}/relations/${userId}`),
    onSuccess: () => queryClient.invalidateQueries([`my-relations-${tradeshowId}`]),
  })
}

export function useUpdateExhibitor(tradeshowId: string) {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<any>, any, any, any>({
    mutationFn: async (exhibitor: Partial<ExhibitorDto>) => {
      const { data } = await axios.put(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/${exhibitor.id}`, exhibitor)
      return data
    },
    onMutate: async (updatedExhibitor) => {
      await queryClient.cancelQueries([`exhibitors-${tradeshowId}`])

      const previousExhibitors: Exhibitor[] = queryClient.getQueryData([`exhibitors-${tradeshowId}`]) || []
      const previousExhibitorIndex = previousExhibitors?.findIndex((exhibitor) => exhibitor.id === updatedExhibitor.id)
      const previousExhibitor = { ...previousExhibitors[previousExhibitorIndex] }

      const updatedExhibitors = [...previousExhibitors]
      updatedExhibitors[previousExhibitorIndex] = new Exhibitor(updatedExhibitor)

      queryClient.setQueryData([`exhibitors-${tradeshowId}`], updatedExhibitors)

      return { previousExhibitor, updatedExhibitor }
    },
    onError: (err, newExhibitor, context) => {
      queryClient.setQueryData([`exhibitors-${tradeshowId}`, context.updatedExhibitor.id], context.previousExhibitor)
    },
  })
}

export function useDeleteExhibitor(tradeshowId: string) {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<void>, any, any, any>({
    mutationFn: async (id) => axios.delete(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/${id}`),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [`exhibitors-${tradeshowId}`] }),
  })
}

// /exhibitors/import
export function useImportExhibitor(tradeshowId: string) {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<void>, any, any, any>({
    mutationFn: async (csv) => axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/import`, csv),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['exhibitors'] }),
  })
}

// /exhibitors/send-emails
export function useSendEmailsExhibitor(tradeshowId: string) {
  return useMutation<AxiosResponse<void>, any, void, any>({
    mutationFn: async () => axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/send-emails`),
  })
}

// /exhibitors/:id/send-email
export function useSendEmailExhibitor(tradeshowId: string) {
  return useMutation<AxiosResponse<void>, any, any, any>({
    mutationFn: async (id) => axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/${id}/send-email`),
  })
}
