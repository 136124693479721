import { ActionIcon, Box, Grid, Text } from '@mantine/core'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { Relation } from 'api/dto/relation'
import { Visitor } from 'api/dto/visitor'
import { useTranslation } from 'react-i18next'

interface VisitorTileProps {
  item: Visitor
  editRelation: (relation: Relation) => void
  deleteRelation: (relation: Relation) => void
}

export function VisitorTile({ item, editRelation, deleteRelation }: VisitorTileProps) {
  const { t } = useTranslation()
  return (
    <Box
      display="flex"
      maw={550}
      mih={227}
      p={20}
      sx={{
        width: '100%',
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px;',
        flexDirection: 'column',
        color: '#2B2B2B',
        backgroundColor: 'white',
      }}
    >
      <Box pos="relative">
        <Box pos="absolute" display="flex" sx={{ top: -10, right: -10 }}>
          <ActionIcon onClick={() => editRelation({ id: item.id, note: item.note })} variant="outline" color="green">
            <IconEdit size={20} />
          </ActionIcon>
          <ActionIcon ml={10} onClick={() => deleteRelation({ id: item.id, fullName: item.fullName })} variant="outline" color="red">
            <IconTrash size={20} />
          </ActionIcon>
        </Box>
        <Grid>
          <Grid.Col span={4}>
            <Text fw={500} c="custom-primary-color.0" size="12px" lh="20px" lts="-0.03px" tt="uppercase">
              {t('visitor.fullName')}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="12px" lh="20px" lts="-0.03px">
              {item.fullName}
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text fw={500} c="custom-primary-color.0" size="12px" lh="20px" lts="-0.03px" tt="uppercase">
              {t('visitor.email')}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="12px" lh="20px" lts="-0.03px">
              {item.email}
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text fw={500} c="custom-primary-color.0" size="12px" lh="20px" lts="-0.03px" tt="uppercase">
              {t('visitor.company')}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="12px" lh="20px" lts="-0.03px">
              {item.companyName}
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text fw={500} c="custom-primary-color.0" size="12px" lh="20px" lts="-0.03px" tt="uppercase">
              {t('visitor.company-phone')}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="12px" lh="20px" lts="-0.03px">
              {item.companyPhone}
            </Text>
          </Grid.Col>
        </Grid>
        <Box
          mt={10}
          sx={{
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '-0.03px',
          }}
        >
          <Text
            weight={500}
            sx={(theme) => ({
              textTransform: 'uppercase',
              color: theme.colors['custom-primary-color'][0],
            })}
          >
            {t('visitor.note')}
          </Text>
          <Text mt={5}>{item.note}</Text>
        </Box>
      </Box>
    </Box>
  )
}
