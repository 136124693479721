import './i18n'
import { createRoot } from 'react-dom/client'
import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { theme } from 'theme/theme'
import { HashRouter } from 'react-router-dom'
import { App } from 'app'
import { useState } from 'react'
import { setDefaultUrl } from 'config/axios'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ProvideAuth } from 'hooks/useAuth'
import { environment } from 'config/environment'
import * as Sentry from '@sentry/react'

if (!environment.isLocal) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [new Sentry.BrowserTracing()],
    maxBreadcrumbs: 50,
    debug: !environment.isProd,
    environment: environment.envName,
    tracesSampleRate: environment.isProd ? 1 : 0.25,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
  })
}

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

const container = document.getElementById('root')
const root = createRoot(container!)

setDefaultUrl(import.meta.env.VITE_API_BASE_URL)

const Index = () => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light')
  const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  return (
    <QueryClientProvider client={queryClient}>
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider theme={{ ...theme, colorScheme }} withNormalizeCSS withGlobalStyles>
          <HashRouter>
            <ProvideAuth>
              <App />
            </ProvideAuth>
          </HashRouter>
          <Notifications />
        </MantineProvider>
      </ColorSchemeProvider>
    </QueryClientProvider>
  )
}

root.render(<Index />)
