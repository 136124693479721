import axios from 'config/axios'
import { useQuery } from '@tanstack/react-query'
import { environment } from 'config/environment'

export function useEmailTemplateTypes() {
  return useQuery(
    ['email-template-types'],
    async () => {
      const { data } = await axios.get<string[]>(`${environment.restApi.resourceUrl.emailTemplateTypes}`)
      return data
    },
    {
      select: (response) => response,
    },
  )
}
