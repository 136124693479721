import { Box, Button, Group, Modal, Text, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { Relation } from 'api/dto/relation'
import { useAddExhibitorRelation } from 'api/query/exhibitor'
import { useUser } from 'api/query/user'
import { QrScanner } from 'components/scanner/qr-scanner'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface EditMyVisitorProps {
  relation?: Relation
  tradeShowId?: string
  exhibitorId?: string
  opened: boolean
  onClose: () => void
}

export function EditMyRelation({ opened, onClose, relation, tradeShowId, exhibitorId }: EditMyVisitorProps) {
  const { t } = useTranslation()
  const form = useForm<Partial<Relation>>({ validateInputOnChange: true })
  const { data: visitor } = useUser(relation?.id, tradeShowId)
  const { mutateAsync } = useAddExhibitorRelation(exhibitorId, tradeShowId)
  const [submitting, setSubmitting] = useState(false)

  const isSmallDevice = useMediaQuery('(max-width: 864px)')

  useEffect(() => {
    if (opened) {
      form.setValues({ ...relation })
    } else {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  async function onSubmit(values: Partial<Relation>) {
    setSubmitting(true)
    try {
      await mutateAsync(values)
    } catch (error) {
      showNotification({
        title: t('common.error.title'),
        message: t('common.error.message'),
        color: 'red',
      })
    }
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="xl" fullScreen={isSmallDevice} opened={opened} onClose={onClose} title="Edit">
      <form onSubmit={form.onSubmit(onSubmit)}>
        {form.values.id && (
          <Box
            mb={20}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 10,
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          >
            <Box>
              <Text weight={600} size="md">
                {visitor?.companyName}
              </Text>
              <Text>{visitor?.fullName}</Text>
            </Box>
          </Box>
        )}

        <Textarea minRows={isSmallDevice ? 5 : 3} label={t('visitor.note')} mt={5} {...form.getInputProps('note')} />
        <Group mt={30} position="right">
          <Button variant="outline" disabled={submitting} size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="outline" loading={submitting} disabled={submitting || !form.isValid()} size="xs" color="green" type="submit">
            {t('common.update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
