import { Box, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'utils/date-time'

export function Banner({ deletionDate, isFromAdmin }: { deletionDate?: Date; isFromAdmin?: boolean }) {
  const { t } = useTranslation()

  if (!deletionDate) {
    return null
  }

  if (DateTime.fromDate(deletionDate).isBefore(DateTime.now())) {
    return null
  }

  return (
    <Box
      w={isFromAdmin ? 'calc(100% + 40px)' : '100%'}
      pos={isFromAdmin ? 'absolute' : 'initial'}
      h={60}
      display="flex"
      sx={(theme) => {
        const pos = isFromAdmin ? { top: -15, left: -20 } : {}

        return {
          ...pos,
          alignItems: 'center',
          backgroundColor: theme.colors['custom-primary-color'][0],
          color: 'white',
          padding: 10,
          justifyContent: 'center',
        }
      }}
    >
      <Text size="xs">{t('tradeshow.banner.deletionDate', { date: DateTime.fromDate(deletionDate).toHuman() })}</Text>
    </Box>
  )
}
