/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Checkbox, MultiSelect, Modal, NumberInput, Radio, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { PaymentType } from 'api/dto/payment'
import { CreateVisitor, Source, Visitor } from 'api/dto/visitor'
import { useTradeshowPrices } from 'api/query/price'
import { useAddVisitorAdmin } from 'api/query/visitor'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface AddVisitorProps {
  opened: boolean
  onClose: () => void
}

export function AddVisitor({ opened, onClose }: AddVisitorProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const form = useForm<Partial<CreateVisitor>>({
    initialValues: {
      paymentInfo: {
        priceIds: [],
        amount: 0,
      },
    },
    validate: {
      firstName: (value) => (value ? null : 'Invalid first name'),
      lastName: (value) => (value ? null : 'Invalid last name'),
      email: (value) => (/^\S+@\S+$/.test(value || '') ? null : 'Invalid email'),
      companyName: (value) => (value ? null : 'Invalid company name'),
    },
    validateInputOnChange: true,
  })
  const { tradeshowId } = useParams()
  const { data: prices } = useTradeshowPrices(tradeshowId)
  const { mutateAsync: addVisitor } = useAddVisitorAdmin(tradeshowId)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!opened) {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  function getVisitor() {
    const values = form.values

    const visitor: Partial<CreateVisitor> = {
      ...values,
      tradeShowId: tradeshowId!,
      active: true,
      paymentInfo: {
        ...values.paymentInfo,
        amount: 0,
      },
      importedFrom: Source.ADMIN,
    }

    return visitor
  }

  function validateForm() {
    form.validate()

    if (Object.keys(form.errors).length > 0) {
      return false
    }

    return true
  }

  async function onSubmit() {
    if (!validateForm()) {
      showNotification({
        message: 'Form is not valid',
        color: 'red',
      })

      return
    }
    setSubmitting(true)
    const visitor = getVisitor()
    await addVisitor(visitor)
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title={t('visitor.add-visitor')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Box display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput withAsterisk w="100%" label={t('visitor.firstName')} {...form.getInputProps('firstName')} />
          <TextInput withAsterisk w="100%" label={t('visitor.lastName')} {...form.getInputProps('lastName')} />
        </Box>
        <Box mt={5} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput withAsterisk w="100%" type="email" label={t('visitor.email')} {...form.getInputProps('email')} />
          <TextInput w="100%" label={t('visitor.category')} {...form.getInputProps('category')} />
        </Box>
        <Box mt={5} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput withAsterisk w="100%" label={t('visitor.company')} {...form.getInputProps('companyName')} />
          <TextInput w="100%" label={t('visitor.company-phone')} {...form.getInputProps('companyPhone')} />
        </Box>
        <Box mt={10} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput w="100%" label={t('visitor.job-title')} {...form.getInputProps('jobTitle')} />
        </Box>

        <Box>
          <MultiSelect
            label={t('visitor.prices')}
            data={prices?.map((p) => ({ label: language === 'en' ? p.nameEn : p.nameFr, value: p.id })) || []}
            onChange={(value) => {
              form.setFieldValue('paymentInfo.priceIds', value)
            }}
            value={form.values.paymentInfo?.priceIds}
          />
        </Box>

        <Box mt={10} display="flex" sx={{ alignItems: 'center' }}>
          <Textarea rows={3} w="100%" label={t('visitor.internal-notes')} {...form.getInputProps('internalNotes')} />
        </Box>

        <Box mt={20} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <Checkbox
            w="100%"
            size="sm"
            color="custom-primary-color.0"
            sx={{
              '.mantine-Checkbox-body': {
                alignItems: 'center',
              },
            }}
            label={t('visitor.consent')}
            {...form.getInputProps('hasConsent', { type: 'checkbox' })}
          />
        </Box>

        <Box mt={30} display="flex" w="100%">
          <Button disabled={submitting} variant="outline" size="xs" color="red" onClick={onClose}>
            {t('visitor.cancel')}
          </Button>

          <Box display="flex" w="100%" sx={{ justifyContent: 'flex-end', gap: 10 }}>
            <Button loading={submitting} disabled={submitting || !form.isValid()} size="xs" variant="outline" color="green" type="submit">
              {t('visitor.add-registration')}
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  )
}
