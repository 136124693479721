import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Scanner from 'qr-scanner'
import { Box } from '@mantine/core'

interface QrScannerProps {
  onScan: (result: { data: string }) => void
}

export function QrScanner({ onScan }: QrScannerProps) {
  const [hasStarted, setHasStarted] = useState(false)
  const [scanner, setScanner] = useState<Scanner | null>(null)

  const videoRef = useCallback(
    (node: HTMLVideoElement | null) => {
      if (node !== null && !scanner) {
        setScanner(
          new Scanner(node, onScan, {
            highlightScanRegion: true,
            maxScansPerSecond: 10,
            highlightCodeOutline: true,
            preferredCamera: 'environment',
          }),
        )
      }
    },
    [onScan, scanner],
  )

  useEffect(() => {
    if (scanner && !hasStarted) {
      void scanner.start()
      setHasStarted(true)
    }
  }, [hasStarted, scanner])

  return <video style={{ objectFit: 'cover' }} width="100%" height="100%" ref={(node) => videoRef(node)} />
}
