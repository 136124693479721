import { Button, Group, Modal, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Relation } from 'api/dto/relation'
import { Visitor } from 'api/dto/visitor'
import { useDeleteExhibitorRelation } from 'api/query/exhibitor'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface DeleteVisitorProps {
  opened: boolean
  onClose: () => void
  relation?: Relation
}

export function DeleteMyRelation({ relation, opened, onClose }: DeleteVisitorProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const form = useForm()
  const { mutateAsync: deleteVisitor } = useDeleteExhibitorRelation(user?.exhibitorId, user?.tradeShowId)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!opened) {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  async function onSubmit() {
    if (!relation?.id) {
      return
    }

    setSubmitting(true)
    await deleteVisitor(relation.id)
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title="Delete">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text size="15px">{t('visitor.delete-visitor', { replace: { name: relation?.fullName } })}</Text>

        <Group mt={30} position="right">
          <Button disabled={submitting} variant="outline" size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>

          <Button loading={submitting} disabled={submitting || !form.isValid()} size="xs" variant="outline" color="green" type="submit">
            {t('common.delete')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
