import { ActionIcon, Badge, Box, Button, LoadingOverlay, Menu, Text } from '@mantine/core'
import { IconEdit, IconMail, IconPlus, IconPrinter, IconSettings, IconTrash, IconUserCircle, IconUsers } from '@tabler/icons-react'
import { Row } from '@tanstack/react-table'
import { Source, Visitor } from 'api/dto/visitor'
import { useVisitors } from 'api/query/visitor'
import axios from 'config/axios'
import { Loading } from 'components/loading/loading'
import { UploadModal } from 'components/modal/upload'
import { AddVisitor } from 'components/modal/visitor/add.visitor'
import { EditVisitor } from 'components/modal/visitor/edit.visitor'
import { ColumnWithFilter, Table } from 'components/table'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { environment } from 'config/environment'
import { useQueryClient } from '@tanstack/react-query'
import { DeleteVisitor } from 'components/modal/visitor/delete.visitor'
import { showNotification } from '@mantine/notifications'
import { printVisitorBadge } from 'utils/badge'
import { saveBase64ToFile } from 'utils/base64'
import { SendEmailsVisitor } from 'components/modal/visitor/send-emails.visitor'
import { SendEmailVisitor } from 'components/modal/visitor/send-email.visitor'

export function Visitors() {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { tradeshowId } = useParams()
  const queryClient = useQueryClient()
  const { data: visitors, isLoading } = useVisitors(tradeshowId)
  const [addVisitorModalOpened, setAddVisitorModalOpened] = useState(false)
  const [importVisitorsOpened, setImportVisitorsOpened] = useState(false)
  const [editVisitor, setEditVisitor] = useState<Visitor | undefined>()
  const [deleteVisitor, setDeleteVisitor] = useState<Visitor | undefined>()
  const [sendVisitorEmail, setSendVisitorEmail] = useState<Visitor | undefined>()
  const [isPrinting, setIsPrinting] = useState(false)
  const [sendEmailOpened, setSendEmailOpened] = useState(false)

  const actionButtons = (rows: Row<Visitor>[]) => [
    <Button
      h={44}
      color="custom-primary-color.0"
      leftIcon={<IconPlus size={17} />}
      size="sm"
      onClick={() => setAddVisitorModalOpened(true)}
    >
      {t('visitor.add')}
    </Button>,
  ]

  const columns: ColumnWithFilter<Visitor>[] = [
    {
      id: 'print',
      cell: (props) => (
        <Box>
          <ActionIcon
            variant="outline"
            size="md"
            onClick={() => {
              setIsPrinting(true)
              showNotification({
                message: 'Downloading badge... Please wait',
                color: 'green',
                title: 'Downloading...',
                loading: true,
                autoClose: 3000,
              })

              // todo, spinner stops after timeout
              setTimeout(async () => {
                if (!tradeshowId || !props.row.original.id) {
                  return
                }

                const badge = await printVisitorBadge(tradeshowId, props.row.original.id)

                if (!badge) {
                  setIsPrinting(false)
                  return
                }

                saveBase64ToFile(badge, `visitor-badge-${props.row.original.id}.png`)
                setIsPrinting(false)
              }, 400)
            }}
          >
            <IconPrinter size="20px" />
          </ActionIcon>
        </Box>
      ),
    },
    {
      id: 'source',
      header: t('visitor.source'),
      cell: (props) => {
        if (props.row.original.importedFrom == Source.EVENTBRITE) {
          return (
            <Badge color="orange" variant="light">
              {props.row.original.importedFrom}
            </Badge>
          )
        }

        return (
          <Badge color="green" variant="light">
            {props.row.original.importedFrom}
          </Badge>
        )
      },
      minSize: 100,
    },
    {
      id: 'created',
      header: t('visitor.created-at'),
      accessorKey: 'created',
      cell: (props) => <Text size="10px">{dayjs(props.getValue() as string).format('YYYY-MM-DD HH:mm')}</Text>,
      minSize: 130,
    },
    {
      id: 'ticket',
      header: t('visitor.tickets'),
      accessorKey: 'ticket.nameEn',
      filter: (row, id, value) => {
        const ticket = row.original.ticket
        if (!ticket) {
          return false
        }

        if (language == 'fr') {
          return ticket.nameFr.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        }

        return ticket.nameEn.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      },
      cell: (props) => {
        const ticket = props.row.original.ticket
        const addons = props.row.original.getAddons()
        const hasCompanion = props.row.original.hasCompanion()

        if (!ticket) {
          return null
        }

        return (
          <Box>
            <Text size="10px">{language == 'fr' ? ticket?.nameFr : ticket?.nameEn}</Text>
            {addons?.map((addon) => <Text size="8px">{language == 'fr' ? addon.nameFr : addon.nameEn}</Text>)}
            {hasCompanion && <IconUserCircle size={15} />}
          </Box>
        )
      },
      minSize: 150,
    },
    {
      id: 'fullName',
      header: t('visitor.fullName'),
      accessorKey: 'fullName',
      minSize: 100,
    },
    {
      id: 'email',
      header: t('visitor.email'),
      accessorKey: 'email',
      cell: (props) => (props.getValue() as string).toLocaleLowerCase(),
      minSize: 200,
    },
    {
      id: 'company',
      header: t('visitor.company'),
      accessorKey: 'companyName',
      minSize: 100,
    },
    {
      id: 'company-phone',
      header: t('visitor.company-phone'),
      accessorKey: 'companyPhone',
      minSize: 120,
    },
    {
      id: 'category',
      header: t('visitor.category'),
      accessorKey: 'category',
      minSize: 100,
    },
    {
      id: 'job',
      accessorKey: 'jobTitle',
      header: t('visitor.job-title'),
      minSize: 100,
    },
    {
      id: 'internalNotes',
      accessorKey: 'internalNotes',
      header: t('visitor.internal-notes'),
      minSize: 100,
      maxSize: 200,
    },
    {
      id: 'actions',
      header: t('visitor.actions'),
      cell: (props) => (
        <Box display="flex">
          <ActionIcon variant="outline" size="md" onClick={() => setEditVisitor(props.row.original)}>
            <IconEdit size="20px" />
          </ActionIcon>

          <ActionIcon ml={10} variant="outline" size="md" color="red" onClick={() => setDeleteVisitor(props.row.original)}>
            <IconTrash size="20px" />
          </ActionIcon>

          <ActionIcon ml={10} variant="outline" size="md" color="green" onClick={() => setSendVisitorEmail(props.row.original)}>
            <IconMail size="20px" />
          </ActionIcon>
        </Box>
      ),
    },
  ]

  if (isLoading) {
    return <Loading height="calc(100vh - 235px)" size="md" />
  }

  if (!tradeshowId) {
    // todo redirect to 404
    return null
  }

  return (
    <>
      <LoadingOverlay visible={isPrinting} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Box sx={{ position: 'absolute', top: 30, right: 30 }}>
        <Menu withArrow>
          <Menu.Target>
            <Button leftIcon={<IconSettings size={25} />} size="md" variant="outline" color="custom-primary-color.0">
              {t('visitor.settings')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>{t('visitor.title')}</Menu.Label>
            <Menu.Item icon={<IconUsers size={17} />} onClick={() => setImportVisitorsOpened(true)}>
              {t('visitor.import')}
            </Menu.Item>
            <Menu.Item icon={<IconMail size={17} />} onClick={() => setSendEmailOpened(true)}>
              {t('visitor.send-emails')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>
      <Table<Visitor>
        defaultSort={{ id: 'created', desc: true }}
        data={visitors || []}
        columns={columns}
        actionButtons={actionButtons}
        exportTable={{
          filename: `visitors-${dayjs().format('YYYY-MM-DD-HH-mm')}`,
          customParser: (row) => {
            const visitor = new Visitor(row)
            return {
              ...visitor,
              amount: visitor.amount,
              created: dayjs(visitor.created).format('YYYY-MM-DD HH:mm'),
              payment: visitor.getPaymentInfos(language),
            }
          },
        }}
      />
      <AddVisitor opened={addVisitorModalOpened} onClose={() => setAddVisitorModalOpened(false)} />
      <EditVisitor opened={!!editVisitor} onClose={() => setEditVisitor(undefined)} visitor={editVisitor} />
      <DeleteVisitor opened={!!deleteVisitor} onClose={() => setDeleteVisitor(undefined)} visitor={deleteVisitor} />
      <SendEmailVisitor opened={!!sendVisitorEmail} onClose={() => setSendVisitorEmail(undefined)} visitor={sendVisitorEmail} />
      <SendEmailsVisitor opened={sendEmailOpened} onClose={() => setSendEmailOpened(false)} />
      <UploadModal
        opened={importVisitorsOpened}
        onClose={() => setImportVisitorsOpened(false)}
        onUpload={(file, uploadProgress) => {
          const reader = new FileReader()
          reader.addEventListener('load', async (e) => {
            const result = e.target?.result

            await axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors/import`, result, {
              onUploadProgress: uploadProgress,
            })

            await queryClient.invalidateQueries({ queryKey: [`visitors-${tradeshowId}`] })
          })
          reader.readAsText(file)
        }}
      />
    </>
  )
}
