import { Loader, Text } from '@mantine/core'
import { Box } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { useTradeshow } from 'api/query/tradeshow'
import { Loading } from 'components/loading/loading'
import { Logo } from 'components/logo'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getBadge } from 'utils/badge'
import { saveBase64ToFile } from 'utils/base64'
import { QrScanner } from './qr-scanner'

const QR_REFRESH_INTERVAL = 900000

export function Scanner() {
  const { tradeshowId } = useParams()
  const { data: tradeshow, isLoading, isSuccess } = useTradeshow(tradeshowId)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [isDownloading, setIsDownloading] = useState(false)
  const [showScanner, setShowScanner] = useState(true)
  const [downloadSuccess, setDownloadSuccess] = useState(false)
  const [badgeCode, setBadgeCode] = useState<string | undefined>()
  const [hasCameraPermission, setHasCameraPermission] = useState(false)

  const checkCameraPermission = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setHasCameraPermission(false)
      return
    }

    // check current permission
    const permission = await navigator.permissions.query({ name: 'camera' as PermissionName })

    if (permission.state === 'granted') {
      setHasCameraPermission(true)
      return true
    } else if (permission.state === 'denied') {
      setHasCameraPermission(false)
      return
    }

    const result = await navigator.mediaDevices.getUserMedia({ video: true })

    if (result) {
      setHasCameraPermission(true)
      return
    }
  }

  const { start, stop } = useInterval(() => {
    setShowScanner(false)
    setTimeout(() => {
      setShowScanner(true)
    }, 500)
  }, QR_REFRESH_INTERVAL)

  const downloadBadge = useCallback(
    async (code: string) => {
      if (isDownloading) {
        return
      }

      setIsDownloading(true)

      const badge = await getBadge(tradeshowId!, code)

      if (!badge) {
        setTimeout(() => {
          setIsDownloading(false)
        }, 1000)
        return
      }

      saveBase64ToFile(badge, `badge-${code}.png`)
      setDownloadSuccess(true)

      setTimeout(() => {
        setDownloadSuccess(false)
        setIsDownloading(false)
      }, 5000)
    },
    [isDownloading, tradeshowId],
  )

  const checkIfUserHasACamera = async () => {
    const cameras = await navigator.mediaDevices.enumerateDevices()

    const hasCamera = cameras.some((camera) => camera.kind === 'videoinput')

    if (!hasCamera) {
      setHasCameraPermission(false)
    }
  }

  useEffect(() => {
    start()

    return () => stop()
  }, [start, stop])

  useEffect(() => {
    void checkCameraPermission()
    void checkIfUserHasACamera()
  }, [])

  useEffect(() => {
    if (badgeCode) {
      void downloadBadge(badgeCode)
      setBadgeCode(undefined)
    }
  }, [badgeCode, downloadBadge])

  if (isLoading) {
    return <Loading size="xl" />
  }

  if (!isSuccess) {
    // todo redirect to 404
    return null
  }

  if (isDownloading) {
    return (
      <Box
        h="100vh"
        bg="custom-primary-color.0"
        display="flex"
        sx={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        {downloadSuccess ? (
          <>
            <Text maw={600} weight={700} align="center" color="#fff" size="40px">
              {t('scanner.printed-success')}
            </Text>
            <Text mt={20} maw={500} weight={600} align="center" color="#fff" size="34px">
              {t('scanner.printed-success-explanation')}
            </Text>
          </>
        ) : (
          <>
            <Loader size="90px" />
            <Text mt={20} maw={500} align="center" weight={700} color="#fff" size="40px">
              {t('scanner.printing')}
            </Text>
          </>
        )}
      </Box>
    )
  }

  return (
    <Box display="flex" w="100%">
      <Box
        pt={hasCameraPermission ? 40 : 0}
        w={hasCameraPermission ? '40%' : '100%'}
        h="100vh"
        bg="custom-primary-color.0"
        display="flex"
        sx={{ alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}
      >
        <Logo white width={300} />
        <Box mt={40}>
          <Text align="center" size="40px" color="custom-secondary-color.0" weight={700} sx={{ whiteSpace: 'pre-line' }}>
            {t('scanner.welcome', { replace: { tradeshowName: language === 'fr' ? tradeshow?.nameFr : tradeshow?.nameEn } })}
          </Text>
        </Box>
        <Box mt={40}>
          <Text align="center" size="25px" color="custom-secondary-color.0" weight={600} sx={{ whiteSpace: 'pre-line' }}>
            {t('scanner.presentation', { replace: { tradeshowName: language === 'fr' ? tradeshow?.nameFr : tradeshow?.nameEn } })}
          </Text>
        </Box>
      </Box>
      {!isDownloading && showScanner && hasCameraPermission ? (
        <Box display="flex" w="100%" h="100vh" bg="custom-primary-color.0">
          <QrScanner
            onScan={(res) => {
              if (isDownloading) {
                return
              }

              setBadgeCode(res.data)
            }}
          />
        </Box>
      ) : (
        hasCameraPermission && <Box w="100%" h="100vh" bg="custom-primary-color.0"></Box>
      )}
    </Box>
  )
}
