export interface KioskDto {
  id: string
  tradeShowId: string
}

export class Kiosk {
  id: string
  tradeShowId: string

  constructor(kiosk: KioskDto) {
    this.id = kiosk.id
    this.tradeShowId = kiosk.tradeShowId
  }
}
