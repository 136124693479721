import { AppShell, Box, Navbar } from '@mantine/core'
import { Header } from 'components/header/header'
import { AppLinks } from 'components/links/app-links'
import { Outlet } from 'react-router'

export default function AppLayout() {
  return (
    <AppShell
      pos="relative"
      navbar={
        <Navbar sx={(theme) => ({ backgroundColor: theme.colors['custom-primary-color'][0] })} width={{ base: 200 }} p="xs">
          <Navbar.Section grow mt="md">
            <AppLinks />
          </Navbar.Section>
        </Navbar>
      }
      header={<Header />}
      sx={{
        main: {
          backgroundColor: '#fcfcfc',
        },
      }}
    >
      <Box sx={{ height: '100%', padding: '20px 30px', position: 'relative' }}>
        <Outlet />
      </Box>
    </AppShell>
  )
}
