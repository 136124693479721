/* eslint-disable @typescript-eslint/naming-convention */
import { TextInput as MantineTextInput, TextInputProps } from '@mantine/core'

export function TextInput({ ...props }: TextInputProps) {
  return (
    <MantineTextInput
      size="lg"
      sx={{
        input: {
          borderRadius: '4px !important',
          fontSize: '16px !important',
        },
      }}
      {...props}
    />
  )
}
