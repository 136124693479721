import { ActionIcon, Box, Button } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useTranslation } from 'react-i18next'
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react'
import { AddPrice } from 'components/modal/price/add.price'
import { useState } from 'react'
import { EditPrice } from 'components/modal/price/edit.price'
import { Price } from 'api/dto/price'
import { useTradeshowPrices, useGlobalPrices } from 'api/query/price'
import { useParams } from 'react-router-dom'
import { DeletePrice } from 'components/modal/price/delete.price'

export function Prices() {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { tradeshowId } = useParams()
  const { data: prices, isLoading } = useTradeshowPrices(tradeshowId)
  const { data: globalPrices, isLoading: isLoadingGlobalPrices } = useGlobalPrices()
  const [editPrice, setEditPrice] = useState<Price | undefined>()
  const [deletePrice, setDeletePrice] = useState<Price | undefined>()
  const [addPriceModalOpened, setAddPriceModalOpened] = useState(false)

  const columns: ColumnDef<Price>[] = [
    {
      id: 'name',
      header: t('price.name'),
      cell: (props) => {
        const row = props.row.original
        if (language === 'en') {
          return row.nameEn
        }

        return row.nameFr
      },
    },
    {
      id: 'description',
      header: t('price.description'),
      cell: (props) => {
        const row = props.row.original
        if (language === 'en') {
          return row.descriptionEn
        }

        return row.descriptionFr
      },
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: t('price.priceType'),
      cell: (props) => {
        const type = props.getValue()
        return <Box>{t(`price.type.${type as string}`)}</Box>
      },
    },
    {
      id: 'price',
      accessorKey: 'globalPriceId',
      header: t('price.price'),
      cell: (props) => {
        const price = globalPrices?.find((globalPrice) => globalPrice.id === props.getValue())
        const amount = price?.amount || 0
        const currency = price?.currency || 'CAD'
        return <Box>{`${amount} ${currency.toUpperCase()}`}</Box>
      },
    },
    {
      id: 'globalPriceId',
      accessorKey: 'globalPriceId',
      header: t('price.globalPriceId'),
      cell: (props) => {
        const price = globalPrices?.find((globalPrice) => globalPrice.id === props.getValue())
        if (!price) {
          return null
        }

        return <Box>{price.id}</Box>
      },
    },
    {
      id: 'globalPriceId',
      accessorKey: 'globalPriceId',
      header: t('price.globalPriceName'),
      cell: (props) => {
        const price = globalPrices?.find((globalPrice) => globalPrice.id === props.getValue())
        if (!price) {
          return null
        }

        return <Box>{price.name}</Box>
      },
    },
    {
      id: 'actions',
      header: t('price.actions'),
      cell: (props) => (
        <Box display="flex">
          <ActionIcon mr={5} variant="outline" size="md" onClick={() => setEditPrice(props.row.original)}>
            <IconEdit size="20px" />
          </ActionIcon>

          <ActionIcon color="red" mr={5} variant="outline" size="md" onClick={() => setDeletePrice(props.row.original)}>
            <IconTrash size="20px" />
          </ActionIcon>
        </Box>
      ),
    },
  ]

  if (isLoading || isLoadingGlobalPrices) {
    return <Loading height="calc(100vh - 235px)" size="md" />
  }

  return (
    <>
      <Table<Price>
        data={prices || []}
        columns={columns}
        actionButtons={() => [
          <Button
            h={44}
            size="sm"
            leftIcon={<IconPlus size={20} />}
            color="custom-primary-color.0"
            onClick={() => setAddPriceModalOpened(true)}
          >
            {t('price.create')}
          </Button>,
        ]}
      />
      <AddPrice opened={addPriceModalOpened} onClose={() => setAddPriceModalOpened(false)} />
      <EditPrice opened={!!editPrice} price={editPrice} onClose={() => setEditPrice(undefined)} />
      <DeletePrice opened={!!deletePrice} price={deletePrice} onClose={() => setDeletePrice(undefined)} />
    </>
  )
}
