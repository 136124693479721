import { Box, Button, Group, Modal, Text, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Tradeshow } from 'api/dto/tradeshow'
import { Price } from 'api/dto/price'
import { useDeletePrice } from 'api/query/price'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface DeletePriceProps {
  opened: boolean
  onClose: () => void
  price?: Price
}

export function DeletePrice({ price, opened, onClose }: DeletePriceProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { tradeshowId } = useParams()
  const form = useForm<Tradeshow>({})
  const { mutateAsync: deletePrice } = useDeletePrice()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!opened) {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  async function onSubmit() {
    if (!price?.id) {
      return
    }

    setSubmitting(true)
    await deletePrice({ priceId: price.id, tradeshowId: tradeshowId! })
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title={t('price.delete')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text size="15px">{t('price.delete-price', { replace: { name: language == 'fr' ? price?.nameFr : price?.nameEn } })}</Text>

        <Group mt={30} position="right">
          <Button disabled={submitting} variant="outline" size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>

          <Button loading={submitting} disabled={submitting || !form.isValid()} size="xs" variant="outline" color="green" type="submit">
            {t('common.delete')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
