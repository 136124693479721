import { Box, Button, Checkbox, FileInput, Group, Modal, Select, Text, TextInput, Textarea } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { Tradeshow } from 'api/dto/tradeshow'
import { useEmailTemplateTypes } from 'api/query/email-template-type'
import { useTaxRates } from 'api/query/price'
import { uploadFile, useUpdateTradeshow } from 'api/query/tradeshow'
import { Loading } from 'components/loading/loading'
import { RichTextEditor } from 'components/text/rich-text-editor'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface EditTradeshowProps {
  opened: boolean
  onClose: () => void
  tradeshow?: Tradeshow
}

export function EditTradeshow({ opened, onClose, tradeshow }: EditTradeshowProps) {
  const { t } = useTranslation()
  const form = useForm<Tradeshow>({
    validate: {
      nameEn: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      nameFr: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      startDate: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      endDate: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      config: {
        emailTemplateType: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      },
    },
  })
  const { mutateAsync: editTradeshow } = useUpdateTradeshow()
  const [submitting, setSubmitting] = useState(false)
  const { data: emailTemplateTypes, isLoading: isPending, isSuccess } = useEmailTemplateTypes()
  const { data: taxRates } = useTaxRates()

  const [logo, setLogo] = useState<File | null>(null)
  const [sponsorLogo, setSponsorLogo] = useState<File | null>(null)

  useEffect(() => {
    if (opened) {
      form.setValues({ ...tradeshow, config: { ...(tradeshow as any)?.config, scheduleLink: tradeshow?.config.scheduleLink || {} } })
    } else {
      form.reset()
      setLogo(null)
      setSponsorLogo(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, tradeshow])

  if (isPending) {
    return <Loading />
  }

  if (!isSuccess) {
    showNotification({
      title: t('common.error.title'),
      message: t('common.error.message'),
      color: 'red',
    })

    // TODO: redirect 500
    return null
  }

  async function onSubmit(values: Tradeshow) {
    setSubmitting(true)
    const updatedTradeshow = new Tradeshow(values)

    const config = {
      ...updatedTradeshow.config,
    }

    if (updatedTradeshow.config.scheduleLink && Object.keys(updatedTradeshow.config.scheduleLink).length > 0) {
      if (
        !updatedTradeshow.config.scheduleLink.url ||
        !updatedTradeshow.config.scheduleLink.en ||
        !updatedTradeshow.config.scheduleLink.fr
      ) {
        showNotification({
          title: 'Schedule Config',
          message: 'Missing schedule properties',
          color: 'red',
        })
        return
      }
    }

    if (logo) {
      config.logo = await uploadFile(updatedTradeshow.id, logo)
    }

    if (sponsorLogo) {
      config.sponsorLogo = await uploadFile(updatedTradeshow.id, sponsorLogo)
    }

    await editTradeshow(
      new Tradeshow({
        ...updatedTradeshow,
        config,
      }),
    )

    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title={t('tradeshow.edit')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput mt={10} withAsterisk required label={t('tradeshow.nameEn')} {...form.getInputProps('nameEn')} />
        <TextInput mt={10} withAsterisk required label={t('tradeshow.nameFr')} {...form.getInputProps('nameFr')} />
        <Box mt={10} display="flex">
          <DateInput
            popoverProps={{ withinPortal: true }}
            w="100%"
            withAsterisk
            label={t('tradeshow.start-date')}
            {...form.getInputProps('startDate')}
          />
          <DateInput
            popoverProps={{ withinPortal: true }}
            ml={5}
            w="100%"
            withAsterisk
            label={t('tradeshow.end-date')}
            {...form.getInputProps('endDate')}
          />
        </Box>

        <Box mt={10}>
          <DateInput
            popoverProps={{ withinPortal: true }}
            w="100%"
            withAsterisk
            label={t('tradeshow.deletion-date')}
            {...form.getInputProps('deletionDate')}
          />
        </Box>

        <Box mt={10}>
          <Text color="#212529" size="sm" weight={500}>
            {t('tradeshow.descriptionEn')}
          </Text>
          <RichTextEditor
            editable
            content={form.values.descriptionEn}
            onUpdate={({ editor }) => {
              form.setFieldValue('descriptionEn', editor.getHTML())
            }}
          />
        </Box>
        <Box mt={10}>
          <Text color="#212529" size="sm" weight={500}>
            {t('tradeshow.descriptionFr')}
          </Text>
          <RichTextEditor
            editable
            content={form.values.descriptionFr}
            onUpdate={({ editor }) => {
              form.setFieldValue('descriptionFr', editor.getHTML())
            }}
          />
        </Box>
        <FileInput
          mt={10}
          multiple={false}
          accept="image/png,image/jpeg"
          value={logo}
          onChange={(e) => setLogo(e)}
          label={t('tradeshow.config.logo')}
          placeholder={tradeshow?.config?.logo}
        />
        <FileInput
          mt={10}
          multiple={false}
          accept="image/png,image/jpeg"
          value={sponsorLogo}
          onChange={(e) => setSponsorLogo(e)}
          label={t('tradeshow.config.sponsorLogo')}
          placeholder={tradeshow?.config?.sponsorLogo}
        />
        <Select
          mt={10}
          withAsterisk
          label={t('tradeshow.config.emailTemplateType')}
          data={emailTemplateTypes.map((emailTemplateType) => ({ value: emailTemplateType, label: emailTemplateType }))}
          {...form.getInputProps('config.emailTemplateType')}
        />
        <Select
          mt={10}
          withAsterisk
          label={t('tradeshow.config.taxRate')}
          data={taxRates?.map((tax) => ({ value: tax.id, label: `${tax.name} - ${tax.state} ${tax.percentage}%` })) || []}
          {...form.getInputProps('config.taxRateId')}
        />

        <Checkbox
          mt={10}
          color="custom-primary-color.0"
          label={t('tradeshow.config.hasLeadRetrievalApp')}
          {...form.getInputProps('config.hasLeadRetrievalApp', { type: 'checkbox' })}
        />
        <Checkbox
          mt={10}
          color="custom-primary-color.0"
          label={t('tradeshow.config.hasFoodIncluded')}
          {...form.getInputProps('config.hasFoodIncluded', { type: 'checkbox' })}
        />

        <TextInput mt={10} label={t('tradeshow.config.schedule.url')} {...form.getInputProps('config.scheduleLink.url')} />
        <TextInput mt={5} label={t('tradeshow.config.schedule.en')} {...form.getInputProps('config.scheduleLink.en')} />
        <TextInput mt={5} label={t('tradeshow.config.schedule.fr')} {...form.getInputProps('config.scheduleLink.fr')} />

        <Group mt={30} position="right">
          <Button disabled={submitting} variant="outline" size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>

          <Button loading={submitting} disabled={submitting || !form.isValid()} size="xs" variant="outline" color="green" type="submit">
            {t('common.update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
