import { loadStripe } from '@stripe/stripe-js'
import { Visitor } from 'api/dto/visitor'

import axios from 'config/axios'
import { environment } from 'config/environment'

const stripePromise = loadStripe(import.meta.env.VITE_LOAD_STRIPE)

export async function redirectToCheckout(tradeshowId: string, visitorIds: string[], email: string, price: string, addons?: string[]) {
  const stripe = await stripePromise
  if (!stripe) {
    return
  }

  const { data: url } = await axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/checkout`, {
    visitorIds,
    email,
    priceIds: addons ? [price, ...addons] : [price],
  })

  window.location = url
}
