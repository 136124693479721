import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { CognitoInfos } from 'config/amplify/auth'
import { environment } from 'config/environment'

const infoAxios = axios.create()

export const useCognitoInfo = () =>
  useQuery<CognitoInfos>(['infos'], async () => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const { data } = await infoAxios.get(`${environment.restApi.baseUrl}${environment.restApi.resourceUrl.infos}`)
    return data
  })
