import { Button, Group, Modal, Select, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { Kiosk } from 'api/dto/kiosk'
import { useAddKiosk } from 'api/query/kiosk'
import { useTradeshows } from 'api/query/tradeshow'
import { Loading } from 'components/loading/loading'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

interface AddKioskProps {
  opened: boolean
  onClose: () => void
}

export function AddKiosk({ opened, onClose }: AddKioskProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const form = useForm<Kiosk>({
    validate: {
      id: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      tradeShowId: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
    },
  })
  const { mutateAsync: addKiosk } = useAddKiosk()
  const [submitting, setSubmitting] = useState(false)
  const { data: tradeshows, isLoading: isPending, isSuccess } = useTradeshows()

  useEffect(() => {
    if (opened) {
      form.setValues({
        id: '',
        tradeShowId: undefined,
      })
    } else {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  if (isPending) {
    return <Loading />
  }

  if (!isSuccess) {
    showNotification({
      title: t('common.error.title'),
      message: t('common.error.message'),
      color: 'red',
    })

    // TODO: redirect 500
    return null
  }

  async function onSubmit(values: Kiosk) {
    setSubmitting(true)
    await addKiosk(values)
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title={t('kiosk.add')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput withAsterisk size="sm" label={t('kiosk.id')} placeholder={t('kiosk.id')} {...form.getInputProps('id')} />

        <Select
          mt={5}
          withAsterisk
          label={t('kiosk.tradeshow')}
          dropdownPosition="bottom"
          data={tradeshows.map((tradeshow) => ({ value: tradeshow.id, label: language === 'fr' ? tradeshow.nameFr : tradeshow.nameEn }))}
          withinPortal={true}
          {...form.getInputProps('tradeShowId')}
        />
        <Group mt={30} position="right">
          <Button disabled={submitting} variant="outline" size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>

          <Button loading={submitting} disabled={submitting || !form.isValid()} size="xs" variant="outline" color="green" type="submit">
            {t('common.create')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
