import { useQuery } from '@tanstack/react-query'
import { Visitor, VisitorDto } from 'api/dto/visitor'
import axios from 'axios'
import { environment } from 'config/environment'

export function useUser(userId?: string, tradeshowId?: string) {
  return useQuery(
    [`users-${tradeshowId}-${userId}`],
    async () => {
      const { data: visitor } = await axios.get<VisitorDto>(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/users/${userId}`)

      return new Visitor(visitor)
    },
    { enabled: userId != null && tradeshowId != null },
  )
}
