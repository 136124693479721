import i18next from 'i18n'

export enum PriceType {
  TICKET = 'TICKET',
  ADDON = 'ADDON',
  COMPANION = 'COMPANION',
}

export interface PriceDto {
  id: string
  nameEn: string
  nameFr: string
  descriptionEn: string
  descriptionFr: string
  globalPriceId: string

  type: PriceType
  isSoldOut?: boolean
  waitingListUrl?: string
}

export class Price {
  id: string
  nameEn: string
  nameFr: string
  type: PriceType

  descriptionEn: string
  descriptionFr: string
  globalPriceId: string
  isSoldOut?: boolean
  waitingListUrl?: string

  constructor(price: PriceDto) {
    this.id = price.id
    this.nameEn = price.nameEn
    this.nameFr = price.nameFr
    this.descriptionEn = price.descriptionEn
    this.descriptionFr = price.descriptionFr
    this.globalPriceId = price.globalPriceId
    this.type = price.type
    this.isSoldOut = price.isSoldOut
    this.waitingListUrl = price.waitingListUrl
  }

  get name() {
    return i18next.language === 'en'
      ? this.nameEn + (!this.isSoldOut ? '' : ' (SOLD OUT)')
      : this.nameFr + (!this.isSoldOut ? '' : ' (COMPLET)')
  }

  get description() {
    return i18next.language === 'en' ? this.descriptionEn : this.descriptionFr
  }

  get isAddon() {
    return this.type === PriceType.ADDON
  }

  get isTicket() {
    return this.type === PriceType.TICKET
  }

  get isCompanionPrice() {
    return this.type === PriceType.COMPANION
  }
}

export interface GlobalPriceDto {
  id: string
  name: string
  amount: number
  currency: string
}

export class GlobalPrice {
  id: string
  name: string
  amount: number
  currency: string

  constructor(globalPrice: GlobalPriceDto) {
    this.id = globalPrice.id
    this.name = globalPrice.name
    this.amount = globalPrice.amount
    this.currency = globalPrice.currency
  }
}

export interface TaxRateDto {
  id: string
  name: string
  percentage: number
  country: string
  state: string
}

export class TaxRate {
  id: string
  name: string
  percentage: number
  country: string
  state: string

  constructor(taxRate: TaxRateDto) {
    this.id = taxRate.id
    this.name = taxRate.name
    this.percentage = taxRate.percentage
    this.country = taxRate.country
    this.state = taxRate.state
  }
}

export interface PriceWithQuantityDto {
  id: string
  nameEn: string
  nameFr: string
  descriptionEn: string
  descriptionFr: string
  type: PriceType
  quantity: number
}

export class PriceWithQuantity {
  id: string
  nameEn: string
  nameFr: string
  descriptionEn: string
  descriptionFr: string
  type: PriceType
  quantity: number

  constructor(price: PriceWithQuantityDto) {
    this.id = price.id
    this.nameEn = price.nameEn
    this.nameFr = price.nameFr
    this.descriptionEn = price.descriptionEn
    this.descriptionFr = price.descriptionFr
    this.type = price.type
    this.quantity = price.quantity
  }
}
