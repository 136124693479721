import { PriceWithQuantity } from './price'

export interface StatsDto {
  badge_scanned: number
  badge_printed: number
  prices: PriceWithQuantity[]
}
export class Stats {
  scanned: number
  printed: number
  prices: PriceWithQuantity[]

  constructor(stats: StatsDto) {
    this.scanned = stats.badge_scanned
    this.printed = stats.badge_printed
    this.prices = stats.prices
  }
}
