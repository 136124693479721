import { ActionIcon, Box, Button } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { Tradeshow } from 'api/dto/tradeshow'
import { useTradeshows } from 'api/query/tradeshow'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useTranslation } from 'react-i18next'
import { IconEdit, IconExternalLink, IconHomeLink, IconPlus, IconShare } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { AddTradeshow } from 'components/modal/tradeshow/add.tradeshow'
import { useState } from 'react'
import { EditTradeshow } from 'components/modal/tradeshow/edit.tradeshow'
import { DateTime, DateTimeFormat } from 'utils/date-time'
import { showNotification } from '@mantine/notifications'
import { useTaxRates } from 'api/query/price'

export function Tradeshows() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: tradeshows, isLoading } = useTradeshows()
  const { data: taxRates } = useTaxRates()
  const [selectedTradeshow, setSelectedTradeshow] = useState<Tradeshow | undefined>()
  const [addTradeshowModalOpened, setAddTradeshowModalOpened] = useState(false)

  const columns: ColumnDef<Tradeshow>[] = [
    {
      id: 'name',
      header: t('tradeshow.name'),
      cell: (props) => {
        const row = props.row.original
        return <Box>{row.name}</Box>
      },
    },
    {
      id: 'description',
      header: t('tradeshow.description'),
      cell: (props) => {
        const row = props.row.original
        return <Box>{row.description}</Box>
      },
      maxSize: 200,
    },
    {
      id: 'startDate',
      accessorKey: 'startDate',
      header: t('tradeshow.start-date'),
      cell: (props) => <Box>{DateTime.fromDate(props.getValue() as Date).toFormat(DateTimeFormat.YearMonthAndDay)}</Box>,
    },
    {
      id: 'endDate',
      accessorKey: 'endDate',
      header: t('tradeshow.end-date'),
      cell: (props) => <Box>{DateTime.fromDate(props.getValue() as Date).toFormat(DateTimeFormat.YearMonthAndDay)}</Box>,
    },
    {
      id: 'tradeshow-tax',
      accessorKey: 'config.taxRateId',
      header: t('tradeshow.config.taxRate'),
      cell: (props) => {
        const tax = taxRates?.find((taxRate) => taxRate.id === props.getValue())
        return tax ? <Box>{`${tax.name} - ${tax.state} ${tax.percentage}%`}</Box> : null
      },
    },
    {
      id: 'actions',
      header: t('tradeshow.actions'),
      cell: (props) => (
        <Box display="flex">
          <ActionIcon
            mr={5}
            color="custom-primary-color.0"
            variant="outline"
            size="md"
            onClick={() => {
              showNotification({
                message: t('common.url-copied'),
                color: 'green',
                title: t('common.copied'),
                autoClose: 3000,
              })

              void navigator.clipboard.writeText(`${window.location.host}/#/tradeshows/${props.row.original.id}/register`)
            }}
          >
            <IconShare size="20px" />
          </ActionIcon>
          <ActionIcon mr={5} variant="outline" size="md" onClick={() => setSelectedTradeshow(props.row.original)}>
            <IconEdit size="20px" />
          </ActionIcon>
          <ActionIcon color="blue" variant="outline" size="md" onClick={() => navigate(`/tradeshows/${props.row.original.id}`)}>
            <IconExternalLink size="20px" />
          </ActionIcon>
        </Box>
      ),
    },
  ]

  if (isLoading) {
    return <Loading height="calc(100vh - 235px)" size="md" />
  }

  return (
    <>
      <Table<Tradeshow>
        data={tradeshows || []}
        columns={columns}
        actionButtons={() => [
          <Button
            h={44}
            size="sm"
            leftIcon={<IconPlus size={20} />}
            color="custom-primary-color.0"
            onClick={() => setAddTradeshowModalOpened(true)}
          >
            {t('tradeshow.create')}
          </Button>,
        ]}
      />
      <AddTradeshow opened={addTradeshowModalOpened} onClose={() => setAddTradeshowModalOpened(false)} />
      <EditTradeshow opened={!!selectedTradeshow} tradeshow={selectedTradeshow} onClose={() => setSelectedTradeshow(undefined)} />
    </>
  )
}
