import { Avatar, useMantineTheme, MantineNumberSize, ActionIcon, ActionIconProps, Menu } from '@mantine/core'
import { useAuth } from 'hooks/useAuth'

interface UserProps extends ActionIconProps {
  radius: MantineNumberSize
  size: MantineNumberSize
}

export function User({ size, radius, ...rest }: UserProps) {
  const { user, logout } = useAuth()
  return (
    <Menu>
      <Menu.Target>
        <ActionIcon {...rest}>
          <Avatar radius={radius} size={size} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={logout}>Logout</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
