import { Loading } from 'components/loading/loading'
import { Scanner } from 'components/scanner/scanner'
import { ChangePassword } from 'components/session/change-password'
import { ForgotPassword } from 'components/session/forgot-password'
import { Login } from 'components/session/login'
import { RegisterNewVisitor } from 'components/session/register-new-visitor'
import { RegisterSuccess } from 'components/session/register.success'
import { ResetPassword } from 'components/session/reset-password'
import { useAuth } from 'hooks/useAuth'
import TradeshowLayout from 'layout/tradeshow.layout'
import { AuthLayout } from 'layout/auth.layout'
import { Tradeshows } from 'pages/tradeshows'
import { Exhibitors } from 'pages/exhibitors'
import { MyRelations } from 'pages/my-relations'
import { Visitors } from 'pages/visitors'
import { ReactNode } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import AppLayout from 'layout/app.layout'
import { Kiosks } from 'pages/kiosks'
import { Prices } from 'pages/prices'
import { AdminRelations } from 'pages/admin-relations'
import { Dashboard } from 'pages/dashboard'

export function App() {
  const { appIsHydrated } = useAuth()

  if (!appIsHydrated) {
    return <Loading size="xl" />
  }

  return (
    <Routes>
      <Route path="session" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route index element={<Navigate to="/session/login" />} />
      </Route>

      <Route path="tradeshows/:tradeshowId">
        <Route path="scan" element={<Scanner />} />
        <Route path="register" element={<RegisterNewVisitor />} />
        <Route path="register-success" element={<RegisterSuccess />} />
        <Route path="*" element={<Navigate to="/:tradeshowId/scan" />} />
      </Route>
      <Route
        element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }
      >
        <Route path="my-relations" element={<MyRelations />} />
      </Route>

      <Route
        path="tradeshows"
        element={
          <RequireAuth needsAdminRight>
            <AppLayout />
          </RequireAuth>
        }
      >
        <Route path="kiosks" element={<Kiosks />} />
        <Route index element={<Tradeshows />} />
      </Route>

      <Route
        path="tradeshows/:tradeshowId"
        element={
          <RequireAuth needsAdminRight>
            <TradeshowLayout />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="exhibitors" element={<Exhibitors />} />
        <Route path="exhibitors/:exhibitorId/relations" element={<AdminRelations />} />
        <Route path="visitors" element={<Visitors />} />
        <Route path="prices" element={<Prices />} />
      </Route>
      <Route path="*" element={<Navigate to="/tradeshows" replace />} />
    </Routes>
  )
}

function RequireAuth({ children, needsAdminRight }: { children: ReactNode | ReactNode[]; needsAdminRight?: boolean }) {
  const { isAuthenticated, appIsHydrated, user } = useAuth()
  const location = useLocation()

  if (!isAuthenticated && appIsHydrated) {
    return <Navigate to={'/session/login'} state={{ from: location }} replace />
  }

  if (needsAdminRight && !user?.isAdmin) {
    return <Navigate to={'/my-relations'} state={{ from: location }} replace />
  }

  return <>{children}</>
}
