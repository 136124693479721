import { Box, Button, Group, Modal, Text, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { useAddExhibitorRelation } from 'api/query/exhibitor'
import { useUser } from 'api/query/user'
import { QrScanner } from 'components/scanner/qr-scanner'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AddMyVisitorProps {
  opened: boolean
  onClose: () => void
}

export function AddMyRelation({ opened, onClose }: AddMyVisitorProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const form = useForm<{ id?: string; note?: string }>({ validateInputOnChange: true })
  const { data: visitor } = useUser(form.values.id, user?.tradeShowId)
  const { mutateAsync } = useAddExhibitorRelation(user?.exhibitorId, user?.tradeShowId)
  const [submitting, setSubmitting] = useState(false)

  const isSmallDevice = useMediaQuery('(max-width: 864px)')

  useEffect(() => {
    form.setFieldValue('note', visitor?.note ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitor])

  async function onSubmit(values: { id?: string; note?: string }) {
    setSubmitting(true)
    try {
      await mutateAsync(values)
    } catch (error) {
      showNotification({
        title: t('common.error.title'),
        message: t('common.error.message'),
        color: 'red',
      })
    }
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="xl" fullScreen={isSmallDevice} opened={opened} onClose={onClose} title="Scan">
      <form onSubmit={form.onSubmit(onSubmit)}>
        {!form.values.id && (
          <Box display="flex" w="100%" h="50vh">
            <QrScanner
              onScan={(res) => {
                form.setFieldValue('id', res.data)
              }}
            />
          </Box>
        )}

        {form.values.id && (
          <Box
            mb={20}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 10,
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          >
            <Box>
              <Text weight={600} size="md">
                {visitor?.companyName}
              </Text>
              <Text>{visitor?.fullName}</Text>
            </Box>
            <Button variant="outline" onClick={() => form.setFieldValue('id', undefined)}>
              Scan Again
            </Button>
          </Box>
        )}

        <Textarea minRows={isSmallDevice ? 5 : 3} label={t('visitor.note')} mt={5} {...form.getInputProps('note')} />
        <Group mt={30} position="right">
          <Button variant="outline" disabled={submitting} size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="outline" loading={submitting} disabled={submitting || !form.isValid()} size="xs" color="green" type="submit">
            {t('common.create')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
