import { Button, Group, Modal, Select, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { Kiosk } from 'api/dto/kiosk'
import { useUpdateKiosk } from 'api/query/kiosk'
import { useTradeshows } from 'api/query/tradeshow'
import { Loading } from 'components/loading/loading'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface EditKioskProps {
  opened: boolean
  onClose: () => void
  kiosk?: Kiosk
}

export function EditKiosk({ opened, onClose, kiosk }: EditKioskProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const form = useForm<Kiosk>({
    validate: {
      id: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      tradeShowId: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
    },
  })
  const { mutateAsync: editKiosk } = useUpdateKiosk()
  const [submitting, setSubmitting] = useState(false)
  const { data: tradeshows, isLoading: isPending, isSuccess } = useTradeshows()

  useEffect(() => {
    if (opened) {
      form.setValues({ ...kiosk })
    } else {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, kiosk])

  if (isPending) {
    return <Loading />
  }

  if (!isSuccess) {
    showNotification({
      title: t('common.error.title'),
      message: t('common.error.message'),
      color: 'red',
    })

    // TODO: redirect 500
    return null
  }

  async function onSubmit(values: Kiosk) {
    setSubmitting(true)
    await editKiosk({ id: kiosk?.id, kiosk: values })
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" mih={400} opened={opened} onClose={onClose} title={t('kiosk.edit')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput disabled withAsterisk size="sm" label={t('kiosk.id')} placeholder={t('kiosk.id')} {...form.getInputProps('id')} />
        <Select
          mt={5}
          withAsterisk
          label={t('kiosk.tradeshow')}
          dropdownPosition="bottom"
          data={tradeshows.map((tradeshow) => ({ value: tradeshow.id, label: language === 'fr' ? tradeshow.nameFr : tradeshow.nameEn }))}
          withinPortal={true}
          {...form.getInputProps('tradeShowId')}
        />
        <Group mt={30} position="right">
          <Button disabled={submitting} variant="outline" size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>

          <Button loading={submitting} disabled={submitting || !form.isValid()} size="xs" variant="outline" color="green" type="submit">
            {t('common.update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
