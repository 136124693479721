import { showNotification } from '@mantine/notifications'
import defaultAxios, { AxiosResponse } from 'axios'
import { environment } from 'config/environment'
import envAxios from 'config/axios'
import { useMutation } from '@tanstack/react-query'

const client = defaultAxios.create({ baseURL: environment.restApi.baseUrl })

export async function getBadge(tradeshowId: string, code: string) {
  try {
    const { data } = await client.get<string>(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/badges/${code}`)
    return data
  } catch (error: any) {
    showNotification({
      title: 'Error',
      message: error?.message,
      color: 'custom-primary-color.0',
      autoClose: 5000,
    })
    return null
  }
}

export async function printVisitorBadge(tradeshowId: string, visitorId: string) {
  try {
    const { data } = await client.post<string>(
      `${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors/${visitorId}/print-badge`,
    )
    return data
  } catch (error: any) {
    showNotification({
      title: 'Error',
      message: error?.message,
      color: 'custom-primary-color.0',
      autoClose: 5000,
    })
    return null
  }
}

export function usePrintExhibitorBadge(tradeshowId?: string) {
  return useMutation<string, any, any, any>({
    mutationFn: async (exhibitorId) => {
      const { data } = await envAxios.post(
        `${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/${exhibitorId}/print-badge`,
      )
      return data
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error?.message,
        color: 'custom-primary-color.0',
        autoClose: 5000,
      })
      return null
    },
  })
}

export function useBatchPrintExhibitorsBadge(tradeshowId?: string) {
  return useMutation<string, any, any, any>({
    mutationFn: async (exhibitorIds) => {
      const { data } = await envAxios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/print-badges`, {
        exhibitorIds,
      })
      return data
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error?.message,
        color: 'custom-primary-color.0',
        autoClose: 5000,
      })
      return null
    },
  })
}
