/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Checkbox, Group, Modal, MultiSelect, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { UpdateVisitor, Visitor } from 'api/dto/visitor'
import { useTradeshowPrices } from 'api/query/price'
import { useUpdateVisitor } from 'api/query/visitor'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface EditVisitorProps {
  opened: boolean
  onClose: () => void
  visitor?: UpdateVisitor
}

export function EditVisitor({ opened, onClose, visitor }: EditVisitorProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { tradeshowId } = useParams()
  const form = useForm<Partial<UpdateVisitor>>({ initialValues: { ...visitor }, validateInputOnChange: true })
  const { data: prices } = useTradeshowPrices(tradeshowId)
  const { mutateAsync } = useUpdateVisitor(tradeshowId!)
  const [submitting, setSubmitting] = useState(false)

  async function onSubmit(values: Partial<UpdateVisitor>) {
    setSubmitting(true)
    await mutateAsync(values)
    setSubmitting(false)
    onClose()
  }

  useEffect(() => {
    if (opened) {
      form.setValues({ ...visitor })
    } else {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title={t('visitor.edit-visitor')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Box display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput withAsterisk w="100%" label={t('visitor.firstName')} {...form.getInputProps('firstName')} />
          <TextInput withAsterisk w="100%" label={t('visitor.lastName')} {...form.getInputProps('lastName')} />
        </Box>
        <Box mt={5} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput withAsterisk w="100%" type="email" label={t('visitor.email')} {...form.getInputProps('email')} />
          <TextInput w="100%" label={t('visitor.category')} {...form.getInputProps('category')} />
        </Box>
        <Box mt={5} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput withAsterisk w="100%" label={t('visitor.company')} {...form.getInputProps('companyName')} />
          <TextInput w="100%" label={t('visitor.company-phone')} {...form.getInputProps('companyPhone')} />
        </Box>
        <Box mt={10} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput w="100%" label={t('visitor.job-title')} {...form.getInputProps('jobTitle')} />
        </Box>

        <Box>
          <MultiSelect
            label={t('visitor.prices')}
            data={prices?.map((p) => ({ label: language === 'en' ? p.nameEn : p.nameFr, value: p.id })) || []}
            onChange={(value) => {
              form.setFieldValue('paymentInfo.priceIds', value)
            }}
            value={form.values.paymentInfo?.priceIds}
          />
        </Box>

        <Box mt={10} display="flex" sx={{ alignItems: 'center' }}>
          <Textarea rows={3} w="100%" label={t('visitor.internal-notes')} {...form.getInputProps('internalNotes')} />
        </Box>

        <Box mt={20} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <Checkbox
            w="100%"
            size="sm"
            color="custom-primary-color.0"
            sx={{
              '.mantine-Checkbox-body': {
                alignItems: 'center',
              },
            }}
            label={t('visitor.consent')}
            {...form.getInputProps('hasConsent', { type: 'checkbox' })}
          />
        </Box>

        <Group mt={30} position="right">
          <Button variant="outline" disabled={submitting} size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button loading={submitting} disabled={submitting || !form.isValid()} size="xs" variant="outline" color="green" type="submit">
            {t('common.update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
