import { PriceDto, PriceType } from './price'

export interface VisitorDto {
  id: string
  tradeShowId: string
  firstName: string
  lastName: string
  email: string
  companyName: string
  companyPhone: string
  cellphone: string
  jobTitle?: string
  phoneNumber?: string
  hasConsent: boolean
  paymentInfo?: { prices: PriceDto[]; amount: number }
  importedFrom: Source
  note?: string
  category?: string

  created?: string
  active?: boolean
  companions?: { firstName: string; lastName: string }[]
  dietaryRestrictions?: string
  internalNotes?: string
}

export interface CreateVisitor {
  tradeShowId: string
  firstName: string
  lastName: string
  email: string
  companyName: string
  companyPhone: string
  cellphone: string
  jobTitle?: string
  phoneNumber?: string
  hasConsent: boolean
  paymentInfo?: { priceIds?: string[]; amount: number }
  importedFrom: Source
  note?: string
  category?: string

  created?: string
  active?: boolean
  companions?: { firstName: string; lastName: string }[]
  dietaryRestrictions?: string
  internalNotes?: string
}

export interface UpdateVisitor {
  id: string
  tradeShowId: string
  firstName: string
  lastName: string
  email: string
  companyName: string
  companyPhone: string
  cellphone: string
  jobTitle?: string
  phoneNumber?: string
  hasConsent: boolean
  paymentInfo?: { priceIds?: string[]; amount: number }
  importedFrom: Source
  note?: string
  category?: string

  created?: string
  active?: boolean
  companions?: { firstName: string; lastName: string }[]
  dietaryRestrictions?: string
  internalNotes?: string
}

export enum Source {
  EVENTBRITE = 'Eventbrite',
  WIX = 'Wix',
  DIRECT = 'Direct',
  ADMIN = 'Admin',
}

export class Visitor {
  id: string
  tradeShowId: string
  firstName: string
  lastName: string
  email: string
  companyName: string
  companyPhone: string
  cellphone: string
  jobTitle?: string
  phoneNumber?: string
  hasConsent: boolean
  paymentInfo?: { prices: PriceDto[]; amount: number }
  importedFrom: Source
  note?: string
  category?: string
  created?: string
  active?: boolean
  companions?: { firstName: string; lastName: string }[]
  dietaryRestrictions?: string
  internalNotes?: string

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  constructor(visitor: VisitorDto) {
    this.id = visitor.id
    this.tradeShowId = visitor.tradeShowId
    this.firstName = visitor.firstName
    this.lastName = visitor.lastName
    this.email = visitor.email.toLowerCase().trim()
    this.companyName = visitor.companyName
    this.companyPhone = visitor.companyPhone
    this.cellphone = visitor.cellphone
    this.jobTitle = visitor.jobTitle || ''
    this.phoneNumber = visitor.phoneNumber
    this.hasConsent = visitor.hasConsent
    this.paymentInfo = visitor.paymentInfo
    this.importedFrom = visitor.importedFrom
    this.note = visitor.note
    this.category = visitor.category || ''
    this.created = visitor.created
    this.active = visitor.active
    this.dietaryRestrictions = visitor.dietaryRestrictions
    this.internalNotes = visitor.internalNotes
  }

  hasBeenImportedFrom(source: Source) {
    return this.importedFrom === source
  }

  hasCompanion() {
    return this.paymentInfo?.prices.some((price) => price.type === PriceType.COMPANION)
  }

  get ticket() {
    return this.paymentInfo?.prices.find((price) => price.type === PriceType.TICKET)
  }

  get amount() {
    return this.paymentInfo?.amount
  }

  getPaymentInfos(language: string) {
    return this.paymentInfo?.prices.map((price) => (language == 'fr' ? price.nameFr : price.nameEn)).join('\n')
  }

  getAddons() {
    return this.paymentInfo?.prices.filter((price) => price.type === PriceType.ADDON)
  }
}
