import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Kiosk, KioskDto } from 'api/dto/kiosk'
import { AxiosResponse } from 'axios'
import axios from 'config/axios'
import { environment } from 'config/environment'
import { v4 as uuidv4 } from 'uuid'

export function useKiosk(id?: string) {
  return useQuery(
    ['kiosk', id],
    async () => {
      const { data } = await axios.get<KioskDto>(`${environment.restApi.resourceUrl.kiosks}/${id}`)
      return data
    },
    {
      enabled: id != null,
      select: (response) => new Kiosk(response),
    },
  )
}

export function useKiosks() {
  return useQuery(
    ['kiosks'],
    async () => {
      const { data } = await axios.get<KioskDto[]>(`${environment.restApi.resourceUrl.kiosks}`)
      return data
    },
    {
      select: (response) => response.map((kiosk) => new Kiosk(kiosk)),
    },
  )
}

export function useAddKiosk() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (kiosk: Kiosk) => axios.post(`${environment.restApi.resourceUrl.kiosks}`, { ...kiosk }),
    onSuccess: () => queryClient.invalidateQueries(['kiosks']),
  })
}

export function useUpdateKiosk() {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<any>, any, any, any>({
    mutationFn: async ({ id, kiosk }: { id: string; kiosk: Kiosk }) => {
      const { data } = await axios.put(`${environment.restApi.resourceUrl.kiosks}/${id}`, kiosk)
      return data
    },
    onMutate: async (optimisticResponse) => {
      await queryClient.cancelQueries(['kiosks'])

      const previousKiosks: Kiosk[] = queryClient.getQueryData(['kiosks']) || []
      const previousKioskIndex = previousKiosks?.findIndex((kiosk) => kiosk.id === optimisticResponse.id)
      const previousKiosk = { ...previousKiosks[previousKioskIndex] }

      const updatedKiosks = [...previousKiosks]
      updatedKiosks[previousKioskIndex] = optimisticResponse.kiosk

      queryClient.setQueryData(['kiosks'], updatedKiosks)

      return { previousKiosk, updatedKiosk: optimisticResponse }
    },
    onError: (err, newKiosk, context) => {
      queryClient.setQueryData(['kiosks', context?.updatedKiosk.id], context.previousKiosk)
    },
  })
}

export function useDeleteKiosk() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => axios.delete(`${environment.restApi.resourceUrl.kiosks}/${id}`),
    onSuccess: () => queryClient.invalidateQueries(['kiosks']),
  })
}
