import { Box } from '@mantine/core'

export function Widget({ children, margin }: { children: React.ReactNode; margin?: string | number }) {
  return (
    <Box
      w="100%"
      p={20}
      m={margin}
      mih={150}
      style={{
        border: '1px solid #cecece',
        borderRadius: '.625rem',
        boxShadow: '0rem .25rem .5625rem -.125rem #fff',
        filter: 'drop-shadow(0rem .0625rem .125rem rgba(255, 255, 255, 0.05))',
        backgroundColor: 'white',
      }}
    >
      {children}
    </Box>
  )
}
