import { Anchor, AppShell, Box, Button, Navbar, Text } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'
import { useTradeshow } from 'api/query/tradeshow'
import { Header } from 'components/header/header'
import { MainLinks } from 'components/links/main-links'
import { Loading } from 'components/loading/loading'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'

export default function TradeshowLayout() {
  const { tradeshowId } = useParams()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()
  const { data: tradeshow, isLoading } = useTradeshow(tradeshowId)
  const [marginTop, setMarginTop] = useState()

  if (isLoading) {
    return <Loading size="xl" />
  }

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar sx={(theme) => ({ backgroundColor: theme.colors['custom-primary-color'][0] })} width={{ base: 200 }} p="xs">
          <Navbar.Section grow mt="md">
            <MainLinks />
          </Navbar.Section>
          <Navbar.Section display="flex" sx={{ justifyContent: 'center' }}>
            <Button color="white.0" onClick={() => navigate('/tradeshows')} leftIcon={<IconArrowLeft />} variant="outline">
              <Text ml={10}>{t('sidebar.tradeshows')}</Text>
            </Button>
          </Navbar.Section>
        </Navbar>
      }
      header={<Header />}
      sx={{
        main: {
          backgroundColor: '#fcfcfc',
        },
      }}
    >
      <Box sx={{ height: '100%', padding: '20px 30px', position: 'relative' }}>
        <Box mt={marginTop} mb={30}>
          <Anchor href={`/#/tradeshows/${tradeshowId}`} size="xl">
            {language == 'fr' ? tradeshow?.nameFr : tradeshow?.nameEn}
          </Anchor>
        </Box>

        <Outlet context={[setMarginTop]} />
      </Box>
    </AppShell>
  )
}
