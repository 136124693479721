import { Box, Button, Group, Modal, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Exhibitor } from 'api/dto/exhibitor'
import { useAddExhibitor } from 'api/query/exhibitor'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface AddExhibitorProps {
  opened: boolean
  onClose: () => void
}

export function AddExhibitor({ opened, onClose }: AddExhibitorProps) {
  const { t } = useTranslation()
  const { tradeshowId } = useParams()
  const form = useForm<Partial<Exhibitor>>({ validateInputOnChange: true })
  const { mutateAsync } = useAddExhibitor(tradeshowId!)
  const [submitting, setSubmitting] = useState(false)

  async function onSubmit(values: Partial<Exhibitor>) {
    setSubmitting(true)
    await mutateAsync(values)
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title="Add Exhibitor">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Box display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput withAsterisk w="100%" label={t('exhibitor.firstName')} {...form.getInputProps('firstName')} />
          <TextInput withAsterisk w="100%" label={t('exhibitor.lastName')} {...form.getInputProps('lastName')} />
        </Box>
        <Box display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput withAsterisk w="100%" label={t('exhibitor.email')} {...form.getInputProps('email')} />
          <TextInput w="100%" label={t('exhibitor.category')} {...form.getInputProps('category')} />
        </Box>
        <Box display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput w="100%" label={t('exhibitor.company-name')} {...form.getInputProps('companyName')} />
        </Box>

        <Box>
          <Textarea rows={3} w="100%" label={t('exhibitor.internal-notes')} {...form.getInputProps('internalNotes')} />
        </Box>

        <Group mt={30} position="right">
          <Button variant="outline" disabled={submitting} size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="outline" loading={submitting} disabled={submitting || !form.isValid()} size="xs" color="green" type="submit">
            {t('common.create')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
