import { TextInput, Group, Button, Text, Box, Anchor, Image } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { IconPassword, IconMail } from '@tabler/icons-react'
import { Logo } from 'components/logo'
import { useMediaQuery } from '@mantine/hooks'

interface FormValues {
  email: string
  password: string
}

export const Login = () => {
  const { login } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const isSmallDevice = useMediaQuery('(max-width: 864px)')

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  async function onSubmit(values: FormValues) {
    setIsLoading(true)
    await login(values.email, values.password)
    setIsLoading(false)
  }

  if (isSmallDevice) {
    return (
      <Box w="100%" display="flex" sx={{ flexDirection: 'column' }}>
        <Box pt={30} pl={30}>
          <Logo width={150} />
        </Box>
        <Box
          pl={30}
          pr={30}
          mt={30}
          mb={50}
          h={240}
          sx={(theme) => ({
            background: theme.colors['custom-primary-color'][0],
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            color: theme.white,
          })}
        >
          <Text align="center" weight={500} size="40px">
            {t('authentication.welcome')}
          </Text>
          <Text align="center" mt={20} weight={400} size="30px">
            {t('authentication.please-login')}
          </Text>
        </Box>
        <LoginForm form={form} isLoading={isLoading} onSubmit={onSubmit} t={t} navigate={navigate} />
      </Box>
    )
  }

  return (
    <>
      <Box display="flex" sx={{ flex: 1, flexFlow: 'column', padding: '20px 30px', zIndex: 100 }}>
        <Logo />
        <Box mt={120} mb={50} sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Text align="center" weight={500} size="40px">
            {t('authentication.welcome')}
          </Text>
          <Text align="center" weight={400} size="xl">
            {t('authentication.please-login')}
          </Text>
        </Box>

        <LoginForm form={form} isLoading={isLoading} onSubmit={onSubmit} t={t} navigate={navigate} />
      </Box>
      <Box
        w={400}
        bg="custom-primary-color.0"
        display="flex"
        sx={{
          overflow: 'hidden',
          flexFlow: 'column',
          alignItems: 'center',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          color: 'white',
          paddingTop: '237px',
          position: 'relative',
        }}
      >
        <Box sx={{ position: 'absolute', top: 5, left: '-100px', right: 0, bottom: 0, height: '100%', minWidth: '1264px' }}>
          <Image src="https://cdn.expovisio.com/full-banner.png" alt="additional-img" />
        </Box>
      </Box>
    </>
  )
}

interface LoginFormProps {
  form: UseFormReturnType<
    {
      email: string
      password: string
    },
    (values: { email: string; password: string }) => {
      email: string
      password: string
    }
  >
  onSubmit: (values: FormValues) => Promise<void>
  t: (key: string) => string
  navigate: (path: string) => void
  isLoading: boolean
}

function LoginForm({ form, isLoading, navigate, onSubmit, t }: LoginFormProps) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box w="70%" maw={650}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Box mb={10}>
            <TextInput
              size="lg"
              sx={{
                input: {
                  borderRadius: '4px !important',
                  fontSize: '16px !important',
                },
              }}
              icon={<IconMail width={15} height={15} stroke="#cecece" />}
              placeholder={t('authentication.email') || ''}
              {...form.getInputProps('email')}
            />
          </Box>

          <Box mb={30}>
            <TextInput
              mb={5}
              size="lg"
              sx={{
                input: {
                  borderRadius: '4px !important',
                  fontSize: '16px !important',
                },
              }}
              icon={<IconPassword width={15} height={15} stroke="#cecece" />}
              type="password"
              placeholder={t('authentication.password') || ''}
              {...form.getInputProps('password')}
            />
            <Box mt={10} display="flex" sx={{ justifyContent: 'flex-end' }}>
              <Anchor size="sm" underline onClick={() => navigate('/session/forgot-password')}>
                {t('authentication.forgot-password')}
              </Anchor>
            </Box>
          </Box>

          <Group position="center">
            <Button
              w={234}
              h={50}
              color="custom-primary-color.0"
              radius="sm"
              type="submit"
              loading={isLoading}
              loaderPosition="right"
              sx={{
                fontSize: '18px',
                lineHeight: '22px',
                fontWeight: 400,
              }}
            >
              {t('common.login')}
            </Button>
          </Group>
        </form>
      </Box>
    </Box>
  )
}
