import { ActionIcon, Box, Button } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useTranslation } from 'react-i18next'
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react'
import { AddKiosk } from 'components/modal/kiosk/add.kiosk'
import { useState } from 'react'
import { EditKiosk } from 'components/modal/kiosk/edit.kiosk'
import { Kiosk } from 'api/dto/kiosk'
import { useKiosks } from 'api/query/kiosk'
import { useTradeshows } from 'api/query/tradeshow'
import { DeleteKiosk } from 'components/modal/kiosk/delete.kiosk'

export function Kiosks() {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { data: kiosks, isLoading } = useKiosks()
  const { data: tradeshows, isLoading: isLoadingTradeshows } = useTradeshows()
  const [editSelectedKiosk, setEditSelectedKiosk] = useState<Kiosk | undefined>()
  const [deleteSelectedKiosk, setDeleteSelectedKiosk] = useState<Kiosk | undefined>()
  const [addKioskModalOpened, setAddKioskModalOpened] = useState(false)

  const columns: ColumnDef<Kiosk>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('kiosk.id'),
    },
    {
      id: 'tradeshow',
      accessorKey: 'tradeShowId',
      header: t('kiosk.tradeshow'),
      cell: (props) => {
        const tradeshow = tradeshows?.find((tradeshow) => tradeshow.id === props.getValue())
        return <Box>{language == 'fr' ? tradeshow?.nameFr : tradeshow?.nameEn}</Box>
      },
    },
    {
      id: 'actions',
      header: t('kiosk.actions'),
      cell: (props) => (
        <Box display="flex">
          <ActionIcon mr={5} variant="outline" size="md" onClick={() => setEditSelectedKiosk(props.row.original)}>
            <IconEdit size="20px" />
          </ActionIcon>
          <ActionIcon variant="outline" color="red" size="md" onClick={() => setDeleteSelectedKiosk(props.row.original)}>
            <IconTrash size="20px" />
          </ActionIcon>
        </Box>
      ),
    },
  ]

  if (isLoading || isLoadingTradeshows) {
    return <Loading height="calc(100vh - 235px)" size="md" />
  }

  return (
    <>
      <Table<Kiosk>
        data={kiosks || []}
        columns={columns}
        actionButtons={() => [
          <Button
            h={44}
            size="sm"
            leftIcon={<IconPlus size={20} />}
            color="custom-primary-color.0"
            onClick={() => setAddKioskModalOpened(true)}
          >
            {t('kiosk.create')}
          </Button>,
        ]}
      />
      <AddKiosk opened={addKioskModalOpened} onClose={() => setAddKioskModalOpened(false)} />
      <EditKiosk opened={!!editSelectedKiosk} kiosk={editSelectedKiosk} onClose={() => setEditSelectedKiosk(undefined)} />
      <DeleteKiosk opened={!!deleteSelectedKiosk} kiosk={deleteSelectedKiosk} onClose={() => setDeleteSelectedKiosk(undefined)} />
    </>
  )
}
