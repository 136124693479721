import { useQuery } from '@tanstack/react-query'
import { Stats, StatsDto } from 'api/dto/stats'
import axios from 'config/axios'
import { environment } from 'config/environment'
import { useCallback } from 'react'

export const useGetTradeshowStats = (tradeshowId?: string) => {
  return useQuery(
    [`tradeshow-stats-${tradeshowId}`],
    async () => {
      const { data: stats } = await axios.get<StatsDto>(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/stats`)

      return stats
    },
    { enabled: tradeshowId != null, select: useCallback((data: StatsDto) => new Stats(data), []) },
  )
}
