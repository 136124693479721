import { Image } from '@mantine/core'
import { useNavigate } from 'react-router-dom'

// TODO change when there's going to be more than one org
const logoName = 'fencan-logo.png'
const logoNameWhite = 'fencan-logo-white.png'

interface LogoProps {
  logoName?: string
  width?: number
  white?: boolean
}

export function Logo({ width = 100, white }: LogoProps) {
  const logo = white ? `https://cdn.expovisio.com/${logoNameWhite}` : `https://cdn.expovisio.com/${logoName}`
  const navigate = useNavigate()

  return <Image style={{ cursor: 'pointer' }} onClick={() => navigate('/tradeshows')} width={width} alt="logo" src={logo} />
}
