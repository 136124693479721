import { Box, Group } from '@mantine/core'

export function EventEnded() {
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 100,
        flexDirection: 'column',
      }}
    >
      <Group
        position="center"
        sx={{
          flexFlow: 'column',
        }}
      >
        <h1>Event Ended</h1>
        <p>Thank you for attending the event.</p>
      </Group>
    </Box>
  )
}
