export function deleteExtraExportedFields(currentRow: any, forExhibitor?: boolean) {
  delete currentRow.id
  delete currentRow.created
  delete currentRow.active
  delete currentRow.tradeShowId

  if (forExhibitor) {
    delete currentRow.paymentInfo
    delete currentRow.cellphone
    delete currentRow.jobTitle
    delete currentRow.phoneNumber
    delete currentRow.importedFrom
    delete currentRow.category
    delete currentRow.companions
    delete currentRow.dietaryRestrictions
    delete currentRow.hasConsent
    delete currentRow.internalNotes
  }

  return currentRow
}
