/* eslint-disable @typescript-eslint/naming-convention */
import type { MantineThemeOverride } from '@mantine/core'

export const theme: MantineThemeOverride = {
  primaryColor: 'gray',
  colorScheme: 'dark',

  fontSizes: {
    xl: '1.75rem',
  },

  colors: {
    white: ['#fff'],
    'custom-primary-color': ['#850014'],
    'custom-secondary-color': ['#fff'],
  },
}
