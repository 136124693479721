import { AppShell, Box } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Outlet } from 'react-router-dom'

export function AuthLayout() {
  return (
    <AppShell
      styles={(theme) => ({
        main: {
          padding: 0,

          ['@media (min-width: 864px)']: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
          },
        },
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.white,
          display: 'flex',
          height: '100vh',
          maxWidth: '1200px',

          ['@media (min-width: 864px)']: {
            width: '70%',
            height: '720px',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
            borderRadius: theme.radius.md,
          },
        })}
      >
        <Outlet />
      </Box>
    </AppShell>
  )
}
