/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Group, Modal, Progress, Text } from '@mantine/core'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone'
import { showNotification } from '@mantine/notifications'
import { IconFile3d, IconUpload, IconX } from '@tabler/icons-react'
import { AxiosProgressEvent } from 'axios'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface UploadModalProps {
  opened: boolean
  onClose: () => void
  withLoading?: boolean

  onUpload: (file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) => void
}

export function UploadModal({ opened, onClose, onUpload }: UploadModalProps) {
  const { t } = useTranslation()
  const [isUploading, setIsUploading] = useState(false)
  const [uploadPercentageCompleted, setUploadPercentageCompleted] = useState<number | null>(null)
  const [file, setFile] = useState<File | null>()

  const onUploadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 100))
    setUploadPercentageCompleted(percentCompleted)
  }, [])

  const uploadFile = useCallback(() => {
    setIsUploading(true)
    if (file) {
      onUpload(file, onUploadProgress)
    }

    setIsUploading(false)
    setFile(null)
    onClose()
  }, [file, onClose, onUpload, onUploadProgress])

  return (
    <Modal opened={opened} onClose={onClose} title={t('common.upload')}>
      <Dropzone
        maxFiles={1}
        onDrop={(files) => {
          setFile(files[0])
        }}
        onReject={(files) => {
          files[0].errors.forEach((e) =>
            showNotification({
              title: 'An error occurred',
              message: e.message,
              color: 'red',
            }),
          )
        }}
        maxSize={250 * 1024 ** 2}
        accept={[MIME_TYPES.csv]}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 0,
          backgroundColor: theme.colors.gray[0],

          '&[data-accept]': {
            color: theme.white,
            backgroundColor: theme.colors.blue[6],
          },

          '&[data-reject]': {
            color: theme.white,
            backgroundColor: theme.colors.red[6],
          },
        })}
      >
        <Group position="center" spacing="xl" style={{ minHeight: '212px', pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload size="3.2rem" />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size="3.2rem" />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFile3d size="3.2rem" />
          </Dropzone.Idle>

          <Box>
            {file ? (
              <>
                <Text size="md" inline>
                  File to be uploaded
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  {file?.name}
                </Text>
              </>
            ) : (
              <>
                <Text size="md" inline>
                  Drag csv here or click to select file
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  File should not exceed 10mb
                </Text>
              </>
            )}
          </Box>
        </Group>
      </Dropzone>
      {uploadPercentageCompleted && (
        <Box mb={10} mt={10} display="flex" sx={{ flexFlow: 'column', alignItems: 'center' }}>
          <Text size="sm" weight={400}>
            Uploading file... {uploadPercentageCompleted}% completed
          </Text>
          <Progress w="100%" striped animate value={uploadPercentageCompleted} color="teal" size="sm" mt={10} mb={10} />
        </Box>
      )}

      <Group mt={20} position="right">
        <Button disabled={isUploading} variant="outline" size="xs" color="red" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button disabled={isUploading} variant="outline" size="xs" color="green" onClick={uploadFile}>
          {t('common.upload')}
        </Button>
      </Group>
    </Modal>
  )
}
