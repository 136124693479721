import { Button, Group, Modal, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Tradeshow } from 'api/dto/tradeshow'
import { Kiosk } from 'api/dto/kiosk'
import { useDeleteKiosk } from 'api/query/kiosk'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface DeleteKioskProps {
  opened: boolean
  onClose: () => void
  kiosk?: Kiosk
}

export function DeleteKiosk({ kiosk, opened, onClose }: DeleteKioskProps) {
  const { t } = useTranslation()
  const form = useForm<Tradeshow>({})
  const { mutateAsync: deleteKiosk } = useDeleteKiosk()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!opened) {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  async function onSubmit() {
    if (!kiosk?.id) {
      return
    }

    setSubmitting(true)
    await deleteKiosk(kiosk.id)
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title={t('kiosk.delete')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text size="15px">{t('kiosk.delete-kiosk', { replace: { name: kiosk?.id } })}</Text>

        <Group mt={30} position="right">
          <Button disabled={submitting} variant="outline" size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>

          <Button loading={submitting} disabled={submitting || !form.isValid()} size="xs" variant="outline" color="green" type="submit">
            {t('common.delete')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
