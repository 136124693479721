export interface ExhibitorDto {
  id: string
  companyName: string
  firstName: string
  lastName: string
  phoneNumber?: string
  category?: string
  email: string
  created?: string
  internalNotes?: string
}

export class Exhibitor implements ExhibitorDto {
  id: string
  companyName: string
  firstName: string
  lastName: string
  phoneNumber?: string
  category?: string
  email: string
  created?: string
  internalNotes?: string

  constructor(exhibitor: ExhibitorDto) {
    this.id = exhibitor.id
    this.companyName = exhibitor.companyName
    this.firstName = exhibitor.firstName
    this.lastName = exhibitor.lastName
    this.phoneNumber = exhibitor.phoneNumber
    this.category = exhibitor.category
    this.email = exhibitor.email
    this.created = exhibitor.created
    this.internalNotes = exhibitor.internalNotes
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}
