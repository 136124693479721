import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { GlobalPrice, GlobalPriceDto, Price, PriceDto, TaxRate, TaxRateDto } from 'api/dto/price'
import axios, { AxiosResponse } from 'axios'
import { environment } from 'config/environment'

export const useGlobalPrices = () => {
  return useQuery(
    ['global-prices'],
    async () => {
      const { data } = await axios.get<GlobalPriceDto[]>(`${environment.restApi.resourceUrl.globalPrices}`)
      return data
    },
    {
      select: (response) => response.map((price) => new GlobalPrice(price)),
    },
  )
}

export const useTaxRates = () => {
  return useQuery(
    ['tax-rates'],
    async () => {
      const { data } = await axios.get<TaxRateDto[]>(`${environment.restApi.resourceUrl.taxRates}`)
      return data
    },
    {
      select: (response) => response.map((tax) => new TaxRate(tax)),
    },
  )
}

export const useTradeshowPrices = (tradeshowId?: string) => {
  return useQuery(
    ['tradeshows', tradeshowId, 'prices'],
    async () => {
      const { data } = await axios.get<PriceDto[]>(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/prices`)
      return data
    },
    {
      enabled: tradeshowId != null,
      select: (response) => response.map((price) => new Price(price)),
    },
  )
}

export function useAddPrice() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ tradeshowId, price }: { tradeshowId: string; price: Price }) =>
      axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/prices`, price),
    onSuccess: (_, { tradeshowId }) => queryClient.invalidateQueries(['tradeshows', tradeshowId, 'prices']),
  })
}

export function useUpdatePrice() {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<any>, any, any, any>({
    mutationFn: async ({ tradeshowId, price }: { tradeshowId: string; price: Price }) => {
      const { data } = await axios.put(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/prices/${price.id}`, price)
      return data
    },
    onMutate: async ({ price: updatedPrice, tradeshowId }) => {
      await queryClient.cancelQueries(['tradeshows', tradeshowId, 'prices'])

      const previousPrices: Price[] = queryClient.getQueryData(['tradeshows', tradeshowId, 'prices']) || []
      const previousPriceIndex = previousPrices?.findIndex((price) => price.id === updatedPrice.id)
      const previousPrice = { ...previousPrices[previousPriceIndex] }

      const updatedPrices = [...previousPrices]
      updatedPrices[previousPriceIndex] = updatedPrice

      queryClient.setQueryData(['tradeshows', tradeshowId, 'prices'], updatedPrices)

      return { previousPrice, updatedPrice }
    },
    onError: (err, newPrice, context) => {
      queryClient.setQueryData(['tradeshows', context?.tradeshowId, 'prices', context?.updatedPrice.id], context.previousPrice)
    },
  })
}

export function useDeletePrice() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ tradeshowId, priceId }: { tradeshowId: string; priceId: string }) =>
      axios.delete(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/prices/${priceId}`),
    onSuccess: (_, { tradeshowId }) => queryClient.invalidateQueries(['tradeshows', tradeshowId, 'prices']),
  })
}
