import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Tradeshow, TradeshowDto } from 'api/dto/tradeshow'
import axios from 'config/axios'
import { environment } from 'config/environment'
import { useCallback } from 'react'

export function useTradeshow(id?: string) {
  return useQuery(
    ['tradeshow', id],
    async () => {
      const { data } = await axios.get<TradeshowDto>(`${environment.restApi.resourceUrl.tradeshows}/${id}`)
      return data
    },
    {
      enabled: id != null,
      select: useCallback((response: TradeshowDto) => new Tradeshow(response), []),
    },
  )
}

export function useTradeshows() {
  return useQuery(
    ['tradeshows'],
    async () => {
      const { data } = await axios.get<TradeshowDto[]>(`${environment.restApi.resourceUrl.tradeshows}`)
      return data
    },
    {
      select: useCallback((response: TradeshowDto[]) => response.map((tradeshow) => new Tradeshow(tradeshow)), []),
    },
  )
}

export function useAddTradeshow() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (tradeshow: Tradeshow) => {
      const { data } = await axios.post<TradeshowDto>(`${environment.restApi.resourceUrl.tradeshows}`, tradeshow)
      return data
    },
    onSuccess: () => queryClient.invalidateQueries(['tradeshows']),
  })
}

export function useUpdateTradeshow() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (tradeshow: Tradeshow) => {
      const { data } = await axios.put<TradeshowDto>(`${environment.restApi.resourceUrl.tradeshows}/${tradeshow.id}`, tradeshow)
      return data
    },
    onMutate: async (updatedTradeshow) => {
      await queryClient.cancelQueries(['tradeshows'])

      const previousTradeshows: Tradeshow[] = queryClient.getQueryData(['tradeshows']) || []
      const previousTradeshowIndex = previousTradeshows?.findIndex((tradeshow) => tradeshow.id === updatedTradeshow.id)
      const previousTradeshow = { ...previousTradeshows[previousTradeshowIndex] }

      const updatedTradeshows = [...previousTradeshows]
      updatedTradeshows[previousTradeshowIndex] = updatedTradeshow

      queryClient.setQueryData(['tradeshows'], updatedTradeshows)

      return { previousTradeshow, updatedTradeshow }
    },
    onError: (err, newTradeshow, context) => {
      queryClient.setQueryData(['tradeshows', context?.updatedTradeshow.id], context?.previousTradeshow)
    },
  })
}

export function uploadFile(tradeshowId: string, file: File) {
  const reader = new FileReader()
  reader.readAsDataURL(file)

  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      const base64String = reader.result as string

      let encoded = base64String.replace(/^data:(.*,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }

      axios
        .post<{ url: string }>(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/upload`, encoded, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'file-name': file.name,
          },
        })
        .then((response) => resolve(response.data.url))
        .catch((error) => reject(error))
    }
    reader.onerror = (error) => reject(error)
  })
}
