import i18next from 'i18n'

export type TradeShowConfig = {
  logo?: string
  sponsorLogo?: string
  emailTemplateType: string
  hasLeadRetrievalApp: boolean
  hasFoodIncluded: boolean
  taxRateId?: string
  scheduleLink?: {
    url?: string
    en?: string
    fr?: string
  }
}

export interface TradeshowDto {
  id: string
  nameEn: string
  nameFr: string
  startDate: Date
  endDate: Date
  deletionDate?: Date
  descriptionEn?: string
  descriptionFr?: string
  config: TradeShowConfig
}

export class Tradeshow {
  id: string
  nameEn: string
  nameFr: string
  startDate: Date
  endDate: Date
  deletionDate?: Date
  descriptionEn?: string
  descriptionFr?: string
  config: TradeShowConfig

  constructor(tradeshow: TradeshowDto) {
    this.id = tradeshow.id
    this.nameEn = tradeshow.nameEn
    this.nameFr = tradeshow.nameFr
    this.startDate = new Date(tradeshow.startDate)
    this.endDate = new Date(tradeshow.endDate)
    this.deletionDate = tradeshow.deletionDate ? new Date(tradeshow.deletionDate) : undefined
    this.descriptionEn = tradeshow.descriptionEn
    this.descriptionFr = tradeshow.descriptionFr
    this.config = tradeshow.config
  }

  get name() {
    return i18next.language === 'en' ? this.nameEn : this.nameFr
  }

  get description() {
    return i18next.language === 'en' ? this.descriptionEn : this.descriptionFr
  }
}
